import React, { useState } from 'react'
import Auction from './AuctionSearch'
import * as Yup from 'yup'
import * as Formik from 'formik'
// import SEO from '@/utils/SEO'
import { useTranslation } from 'react-i18next'
function AuctionManage(props) {
    const { t } = useTranslation()
    const [type, setType] = useState('live')

    const sortShow = [
        {
            value: 'ac.id, desc',
            show: t('none'),
        },
        {
            value: 'ac.date_closed, asc',
            show: t('time_remaining'),
        },
        {
            value: 2,
            show: t('ending_today'),
        },
    ]

    const pageOptions = [
        {
            value: '12',
            show: t('results_12_per_page'),
        },
        {
            value: '24',
            show: t('results_24_per_page'),
        },
        {
            value: '36',
            show: t('results_36_per_page'),
        },
    ]
    const search = {
        FormikSearch: {
            limit: 12,
            page: 1,
            orderby: 'ac.id, desc',
            market_status:
                props.match.params.status && props.match.params.status === 'closed' ? 'closed' : '',
            filters: {
                title: {
                    value: '',
                    type: 'like',
                    field: 'ac.title',
                },
                market_status: {
                    value:
                        props.match.params.status && props.match.params.status === 'closed'
                            ? 'closed'
                            : 'open',
                    type: 'in',
                    field: 'ac.market_status',
                },
                p_market_status: {
                    value:
                        props.match.params.status && props.match.params.status === 'closed'
                            ? ''
                            : 'open',
                    type: 'in',
                    field: 'p.market_status',
                },
            },
        },
    }
    return (
        <>
            {/* <SEO title="View Auction" url={window.location.href} /> */}
            <div className="search customContainer">
                <Auction
                    sortShow={sortShow}
                    pageOptions={pageOptions}
                    search={search}
                    searchbytitle={false}
                />
            </div>
        </>
    )
}
export default AuctionManage
