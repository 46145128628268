import { Button } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import './ProductCard.css'
import { currencyFormat } from '@/custom/common/components'
import BuynowContext from '@/product/context/buynow/buynowContext'
import { mapData } from '@/product/common/components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '@/custom/common/components'
const CartItem = (props) => {
    const [product, setProduct] = useState()
    const [reload, setReload] = useState(false)
    const { addToCart } = useContext(BuynowContext)
    const history = useHistory()
    const { t } = useTranslation()
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const validationArray = Yup.object({
        quantity: Yup.number()
            .min(1, `${t('min_quantity')} `)
            .max(
                product ? product.projects_qty - product.projects_sold : 0,
                `${t('max_quantity')} ${
                    product ? product.projects_qty - product.projects_sold : 0
                }`,
            )
            .required(t('enter_quantity')),
    })

    const formik = useFormik({
        initialValues: {
            quantity: 0,
        },
        validationSchema: validationArray,
        onSubmit: debounce((values) => {
            addToCart(
                {
                    cart: [
                        {
                            product_id: product.project_id,
                            qty: values.quantity,
                        },
                    ],
                },
                'qty_change',
            )
        }, 1000),
    })

    useEffect(() => {
        if (product) {
            formik.values.quantity = product ? product.qty : 0
            setReload(!reload)
        }
    }, [product])

    useEffect(() => {
        if (product) {
            if (formik.values.quantity !== product.qty) {
                formik.submitForm()
            }
        }
    }, [formik.values.quantity])

    const formikQuantity = [
        {
            label: '',
            name: 'quantity',
            type: 'number',
            placeholder: 'Qty',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    const textAlignStyle = {
        textAlign: document.body.dir === 'rtl' ? 'right' : 'left',
    }
    return (
        <>
            {product ? (
                <>
                    <div className="cartItem d-flex justify-content-between align-items-start">
                        <div className="itemInfo d-flex justify-content-start">
                            <div className="pclImg">
                                <img
                                    src={
                                        product.projects_avatar &&
                                        product.projects_avatar.includes('http')
                                            ? product.projects_avatar
                                            : global.site_url +
                                              '/uploads/product/' +
                                              product.projects_avatar
                                    }
                                    alt={product.title}
                                />
                            </div>
                            <div className="listContent">
                                <h2
                                    style={textAlignStyle}
                                    className="listProdTitle"
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            `productView/${product?.project_id}`,
                                        )
                                    }
                                >
                                    {product.projects_title}
                                </h2>
                                {props.from == 'buynowcart' ||
                                props.from == 'buynowcheckout' ||
                                props.from == 'offercheckout' ||
                                (props.from == 'invoice' && product.buynow_autype === 'buynow') ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>{t('amount')}:</span>
                                                <span> {currencyFormat(product.amount, t)}</span>
                                            </h5>
                                            <h5 style={textAlignStyle}>
                                                <span>{t('Quantity')}:</span>{' '}
                                                <span> {product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>{t('sub_total')}:</span>
                                                <span> {currencyFormat(product.per_total, t)}</span>
                                            </h5>
                                            {props.from == 'invoice' &&
                                            product.buynow_autype === 'buynow' ? (
                                                <> </>
                                            ) : // <h5>
                                            //     <span>{t('delivery_status')}:</span>
                                            //     <span>
                                            //         {product.delivered === 1
                                            //             ? 'In Transit'
                                            //             : product.delivered === 2
                                            //             ? 'Ready for Pickup'
                                            //             : product.delivered === 3
                                            //             ? 'Completed'
                                            //             : product.delivered === 4
                                            //             ? 'Cancelled'
                                            //             : 'Pending'}
                                            //     </span>
                                            // </h5>
                                            null}
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>
                                                    {t('vat')} ({product.tax_percent}%):
                                                </span>
                                                <span> {currencyFormat(product.total_tax, t)}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>{t('platform_commission')}:</span>
                                                <span>
                                                    {' '}
                                                    {currencyFormat(product.total_premium, t)}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>{t('total')}:</span>
                                                <span>
                                                    {' '}
                                                    {currencyFormat(product.total_amount, t)}
                                                </span>
                                            </h5>
                                        </div>
                                        {product.projects_local_pickup_check &&
                                        product.projects_shipping_check ? (
                                            <h5 style={textAlignStyle}>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center m-0 pick-up">
                                                    <span className="material-icons">
                                                        local_shipping
                                                    </span>
                                                    <span className="pickup-text">
                                                        {t('local pickup and shipping available')}
                                                    </span>
                                                </div>
                                            </h5>
                                        ) : product.projects_shipping_check ? (
                                            <h5 style={textAlignStyle}>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center m-0 pick-up">
                                                    <span className="material-icons">
                                                        local_shipping
                                                    </span>
                                                    <span className="pickup-text">
                                                        {t('Shipping Only')}
                                                    </span>
                                                </div>
                                            </h5>
                                        ) : (
                                            <h5 style={textAlignStyle}>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center m-0 pick-up">
                                                    <img
                                                        src="/assets/svg/truck.svg"
                                                        alt="Locak pickup logo"
                                                    />
                                                    <span className="pickup-text">
                                                        {t('Local pickup only')}
                                                    </span>
                                                </div>
                                            </h5>
                                        )}
                                    </>
                                ) : props.from == 'makeoffercart' ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>Quantity Requested:</span>
                                                <span>{product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>Amount Offered:</span>
                                                <span>{currencyFormat(product.amount, t)}</span>
                                            </h5>
                                        </div>
                                    </>
                                ) : props.from == 'cart' ||
                                  props.from == 'checkout' ||
                                  (props.from == 'invoice' && product.buynow_autype === 'live') ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>{t('sub_total')}:</span>
                                                <span>{currencyFormat(product.per_total, t)}</span>
                                            </h5>
                                            {props.from == 'invoice' &&
                                            product.buynow_autype === 'live' ? (
                                                <> </>
                                            ) : // <h5>
                                            //     <span style={{ display: 'inline' }}>
                                            //         {t('delivery_status')}:
                                            //     </span>
                                            //     <span>
                                            //         {product.delivered === 1
                                            //             ? 'In Transit'
                                            //             : product.delivered === 2
                                            //             ? 'Ready for Pickup'
                                            //             : product.delivered === 3
                                            //             ? 'Completed'
                                            //             : product.delivered === 4
                                            //             ? 'Cancelled'
                                            //             : 'Pending'}
                                            //     </span>
                                            // </h5>

                                            null}
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>
                                                    {t('vat')}({product.tax_percent}%):
                                                </span>
                                                <span>{currencyFormat(product.total_tax, t)}</span>
                                            </h5>
                                            {product?.third_shipping_shipstation_confirmation ? (
                                                <h5 style={textAlignStyle}>
                                                    <a
                                                        href={
                                                            product?.third_shipping_shipstation_confirmation
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        Track Shipment
                                                    </a>
                                                </h5>
                                            ) : null}
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5 style={textAlignStyle}>
                                                <span>{t('total')}:</span>
                                                <span>
                                                    {currencyFormat(product.total_amount, t)}
                                                </span>
                                            </h5>
                                        </div>

                                        {product.projects_local_pickup_check &&
                                        product.projects_shipping_check ? (
                                            <h5 style={textAlignStyle}>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center m-0 pick-up">
                                                    <span className="material-icons">
                                                        local_shipping
                                                    </span>
                                                    <span className="pickup-text">
                                                        {t('local pickup and shipping available')}
                                                    </span>
                                                </div>
                                            </h5>
                                        ) : product.projects_shipping_check ? (
                                            <h5 style={textAlignStyle}>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center m-0 pick-up">
                                                    <span className="material-icons">
                                                        local_shipping
                                                    </span>
                                                    <span className="pickup-text">
                                                        {t('Shipping Only')}
                                                    </span>
                                                </div>
                                            </h5>
                                        ) : (
                                            <h5 style={textAlignStyle}>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center m-0 pick-up">
                                                    <img
                                                        src="/assets/svg/truck.svg"
                                                        alt="Locak pickup logo"
                                                    />
                                                    <span className="pickup-text">
                                                        {t('Local pickup only')}
                                                    </span>
                                                </div>
                                            </h5>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>

                        <div className="listActBtn">
                            {/* <div className="listBidInfo d-flex justify-content-between align-items-center">
                                <p className="d-flex justify-content-center align-items-center">
                                    <span>{currencyFormat(product.buynowamount)}</span>
                                    {props.from == 'cart' ? (
                                        <>
                                            <span className="listDivider">|</span>
                                            <span>Unpaid</span>
                                        </>
                                    ) : null}
                                </p>
                            </div> */}
                            {props.from == 'buynowcart' ? (
                                <>
                                    {props.blockRemoveCart ? null : (
                                        <Button
                                            id="remove_from_cart"
                                            className="removeCart"
                                            onClick={() =>
                                                props.changeOnCart({
                                                    cart_id: product.cart_id,
                                                    qty: product.qty,
                                                    product_id: product.projects_id,
                                                })
                                            }
                                        >
                                            <span className="material-icons-outlined">delete</span>{' '}
                                            {t('remove_from_cart')}
                                        </Button>
                                    )}
                                    <div className="updateCartQuantity">
                                        <Button
                                            disabled={formik.values.quantity <= 1}
                                            onClick={() => {
                                                formik.setFieldValue(
                                                    'quantity',
                                                    parseInt(formik.values.quantity) - 1,
                                                )
                                            }}
                                        >
                                            <span className="material-icons">remove_circle</span>
                                        </Button>
                                        {Object.values(mapData(formikQuantity))}
                                        <Button
                                            onClick={() => {
                                                formik.setFieldValue(
                                                    'quantity',
                                                    parseInt(formik.values.quantity) + 1,
                                                )
                                            }}
                                        >
                                            <span className="material-icons">add_circle</span>
                                        </Button>
                                    </div>
                                </>
                            ) : props.from == 'cart' ? (
                                <>
                                    {product.in_cart === 0 ? (
                                        <Button
                                            id="add_to_cart"
                                            className="removeCart"
                                            onClick={() =>
                                                props.changeOnCart({
                                                    cart: 1,
                                                    id: product.projects_id,
                                                })
                                            }
                                        >
                                            {t('add_to_cart')}
                                        </Button>
                                    ) : (
                                        <Button
                                            id="remove_from_cart"
                                            className="removeCart"
                                            onClick={() =>
                                                props.changeOnCart({
                                                    cart: 0,
                                                    id: product.projects_id,
                                                })
                                            }
                                        >
                                            {t('remove_from_cart')}
                                        </Button>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}
export default CartItem
