import React, { useState, useContext, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import openSocket from 'socket.io-client'
import 'react-image-gallery/styles/css/image-gallery.css'
import './ProductView.css'
import { Breadcrumbs, Drawer, Typography } from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import Loaders from '@/custom/components/molecules/Loaders'
import ProductContext from '@/product/context/product/productContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import { dateFormatFront, dateTimeFormatFunction, asyncForEach } from '@/product/common/components'
import NoRecordsFound from '@/product/components/atoms/NoRecordsFound'
// import Bidding from '@/components/molecules/Bidding/BiddingItem'
import Timer from '@/product/common/timer'
import ListView from '@/custom/components/molecules/ProductCard/ListView'
import GridView from '@/custom/components/molecules/ProductCard/GridView'
import ProductView from '@/custom/components/organisms/ProductViewComponent'
import AuctionContext from '@/product/context/auction/auctionContext'
import { Button } from '@material-ui/core'
import { DirectAPICAll } from '@/product/common/components'
import { messageHandler } from '@/product/common/socketHandler'
import AlertContext from '@/product/context/alert/alertContext'
import AuthContext from '@/product/context/auth/authContext'
import CustomSelect from '@/product/components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '@/product/components/atoms/Inputs/CustomMultiSelect'
import { socket, reInitializeSocket } from '@/product/common/socket'
import ReactPlayer from 'react-player/lazy'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import FilterPanel from '@/custom/components/organisms/FilterPanel'
// import DirectFilterPanel from '@/custom/components/organisms/DirectFilterPanel'
import { CSVLink, CSVDownload } from 'react-csv'
import Popup from '@/custom/components/organisms/Popup'
import { mapData, handleRedirectInternal } from '@/product/common/components'
import { Formik, useFormik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CSVReader } from 'react-papaparse'
import BuyerContext from '@/product/context/buyer/buyerContext'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Pagination } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import AvatarImage from '@/custom/components/atoms/AvatarImage'
import { useTranslation } from 'react-i18next'
// import SEO from '@/utils/SEO'
import AuctionView from '../Auction/AuctionDynamic'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
function accorianTerms(t) {
    return [
        {
            title: 'Description',
            details: 'Terms and Conditions Details',
        },
    ]
}

function VideoAuction(props) {
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const auctionContext = useContext(AuctionContext)
    const { allCategory, allCondition, allLocations, setLoginModal } = customCommonContext
    const { search_allauctions, getAllAuctionProducts } = auctionContext
    const { t } = useTranslation()
    const { getAllSearchProducts, search_allproducts } = useContext(ProductContext)
    const [auctionProducts, setAuctionProducts] = useState([])
    const [manifestProducts, setManifestProducts] = useState([])
    const [auctionDetails, setAuctionDetails] = useState({})
    const [auctionImages, setAuctionImages] = useState([])
    const buyerContext = useContext(BuyerContext)
    let { bidConfirm } = buyerContext
    const [isLoadingAuction, setIsLoadingAuction] = useState(true)
    const [isLoadingProject, setIsLoadingProject] = useState(true)
    const history = useHistory()
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [viewProduct, setViewProduct] = useState([])
    const [csvData, setCsvData] = useState([])
    const { setAlert } = alertContext
    const { user, isAuthenticated, saveSearch } = authContext
    const [bulkPopup, setBulkPoup] = useState(false)
    const [bidInstruction, setBidInstruction] = useState(false)
    const [TableView, setTableView] = useState(false)
    const lvActnRef = useRef()
    const [searchText, setSearchText] = useState('')
    const [termsExpand, setTermsExpand] = useState(false)
    const [expanded, setExpanded] = useState()

    const validationArray = Yup.object({
        //  uploadbulkdoc: Yup.array().required('Required'),
        id: Yup.number(),
        title: Yup.string(),
        amount: Yup.string(),
    })
    //formikSearch for search operations
    const formikSearch = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                subCategory: {
                    value: [],
                    type: 'array',
                    field: 'it.subCategoryTypeId',
                },
                third_sub_category: {
                    value: [],
                    type: 'array',
                    field: 'it.third_sub_categoryID',
                },
                // location: {
                //     value: [],
                //     type: 'array',
                //     field: 'p.location_id',
                // },
                shipType: {
                    value: [],
                    type: 'findinarray',
                    field: 'it.shipTypeId',
                },
                price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
        },
    })

    useEffect(() => {
        formikSearch.setFieldValue(`filters.auctionid.value`, [Number(props.match.params.aid)])
        setIsLoadingAuction(true)
        getAllAuctionProducts(
            {
                limit: 1,
                page: 1,
                orderby: 'ac.date_closed, asc',
                order: '',
                market_status: 'open',
                filters: {
                    auctionid: {
                        value: Number(props.match.params.aid),
                        type: 'in',
                        field: 'ac.id',
                    },
                },
            },
            'liveauction',
        )
    }, [props.match.params.aid])

    useEffect(() => {
        if (formikSearch.values.filters.auctionid.value.length) {
            setIsLoadingProject(true)
            getAllSearchProducts(formikSearch.values, 'searchproducts')
        }
    }, [formikSearch.values])

    useEffect(() => {
        let images = [
            {
                original: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
                thumbnail: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
            },
        ]

        setAuctionImages(images)
    }, [auctionDetails])
    // Used to change page
    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue(`page`, value)
    }

    useEffect(() => {
        if (search_allproducts.from === 'searchproducts') {
            setAuctionProducts(search_allproducts.records.length ? search_allproducts.records : [])
            setTimeout(() => {
                setIsLoadingProject(false)
            }, 1500)
            getAllSearchProducts(
                {
                    limit: search_allproducts.totalRecords,
                    page: 1,
                    orderby: 'p.id, desc',
                    order: '',
                    filters: {
                        auctionid: {
                            value: [Number(props.match.params.aid)],
                            type: 'array',
                            field: 'p.auctionid',
                        },
                    },
                },
                'manifestproducts',
            )
        }
    }, [search_allproducts])
    //get all AuctionDetails
    useEffect(() => {
        if (search_allauctions.from === 'liveauction') {
            if (search_allauctions && search_allauctions.records.length > 0) {
                setAuctionDetails(search_allauctions.records[0])
                setTimeout(() => {
                    setIsLoadingAuction(false)
                }, 1500)
            } else {
                handleRedirectInternal(history, 'Auction')
            }
        }
    }, [search_allauctions])
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }
    // Clears search filter values
    const clearSearchFilter = () => {
        formikSearch.values = {
            ...formikSearch.values,
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    ...formikSearch.values.filters.category,
                    value: [],
                },
                subCategory: {
                    ...formikSearch.values.filters.subCategory,
                    value: [],
                },
                third_sub_category: {
                    ...formikSearch.values.filters.third_sub_category,
                    value: [],
                },
                shipType: {
                    ...formikSearch.values.filters.shipType,
                    value: [],
                },
                location: {
                    ...formikSearch.values.filters.location,
                    value: [],
                },
                price: {
                    ...formikSearch.values.filters.price,
                    value: 0,
                },
                searchbar: {
                    ...formikSearch.values.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...formikSearch.values.filters.auctionid,
                },
            },
        }
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchTextChange()
        }
    }
    //get auctionProducts details
    useEffect(() => {
        if (state.right) {
            const index = auctionProducts.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: auctionProducts[index] })
            }
        }
    }, [auctionProducts])

    useEffect(() => {
        if (auctionDetails.id) {
            init()
        }
    }, [auctionDetails])

    const config = {
        iceServers: [
            {
                urls: process.env.REACT_APP_TURN_URL,
                credential: process.env.REACT_APP_TURN_CREDENTIAL,
                username: process.env.REACT_APP_TURN_USERNAME,
            },
        ],
    }

    let [uri, setUri] = useState([])

    let peerConnection
    const videoRef = useRef()

    const handleSearchTextChange = () => {
        formikSearch.setFieldValue(`filters.searchbar.value`, searchText)
    }
    //socket connection
    useEffect(() => {
        socket.on('offer', (id, description) => {
            console.log('video auction 111', id, description)
            peerConnection = new RTCPeerConnection(config)
            peerConnection
                .setRemoteDescription(description)
                .then(() => peerConnection.createAnswer())
                .then((sdp) => peerConnection.setLocalDescription(sdp))
                .then(() => {
                    socket.emit('answer', id, peerConnection.localDescription)
                })
            peerConnection.ontrack = (event) => {
                console.log('video auction 222', event.streams[0])
                setUri(event.streams[0])
            }
            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    socket.emit('candidate', id, event.candidate)
                }
            }
        })
    }, [socket])

    useEffect(() => {
        socket.on('candidate', (id, candidate) => {
            console.log('video auction 333', id, candidate)
            peerConnection
                .addIceCandidate(new RTCIceCandidate(candidate))
                .catch((e) => console.error(e))
        })
    }, [socket])

    useEffect(() => {
        return () => {
            socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
            if (peerConnection) {
                peerConnection.close()
            }
            reInitializeSocket()
        }
    }, [])

    useEffect(() => {
        socket.on('disconnectPeer', () => {
            console.log('video auction 4444 disconnectPeer')
            peerConnection.close()
        })
    }, [socket])
    // To Reinitialize Socket
    useEffect(() => {
        window.onunload = window.onbeforeunload = () => {
            socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
            if (peerConnection) {
                peerConnection.close()
            }
            reInitializeSocket()
        }
    }, [window])
    //function for videostreaming
    async function init() {
        const payload = {
            auctionid: auctionDetails.id,
        }

        const data = await DirectAPICAll(
            'post',
            `${global.site_url}/api/video/newVideoStream`,
            payload,
        )
        if (data?.data?.data?.responseData?.length) {
            console.log(
                'video auction 55555 broadcasterID',
                data.data.data.responseData[0].sender_stream,
            )
            localStorage.setItem('broadcasterID', data.data.data.responseData[0].sender_stream)
            socket.emit('watcher', data.data.data.responseData[0].sender_stream)
        }
    }

    const viewProductRef = useRef(auctionProducts)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = auctionProducts
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setAuctionProducts,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('cancelBidEmitted', (data) => {
            formikSearch.values.filters.auctionid.value = [Number(props.match.params.aid)]
            getAllSearchProducts(formikSearch.values, 'searchproducts')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('cancelBidEmitted', (data) => {})
            socket.disconnect()
        }
    }, [])

    const pageOptions = [
        {
            value: '12',
            show: '12 Per Page',
        },
        {
            value: '24',
            show: '24 Per Page',
        },
        {
            value: '36',
            show: '36 Per Page',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        {
            value: 'p.wprice, asc',
            show: 'Current Price Low to High',
        },
        {
            value: 'p.wprice, desc',
            show: 'Current Price High to Low',
        },
        {
            value: 'p.date_closed, asc',
            show: 'Time Remaining',
        },
        {
            value: 1,
            show: 'Ending Today',
        },
    ]

    useEffect(() => {
        if (search_allproducts.from === 'manifestproducts') {
            setManifestProducts(search_allproducts.records.length ? search_allproducts.records : [])
        }
    }, [search_allproducts])

    const downloadManifest = async (event, done) => {
        let csv = []
        await asyncForEach(manifestProducts, (data, index) => {
            if (data.auction) {
                let csvdata = {}
                csvdata['Lots#'] = data.id
                csvdata['Title'] = data.title
                allCategory.filter((val, i) => {
                    if (val.id == data.categoryTypeId) {
                        csvdata['Category'] = val.description
                    }
                })
                if (!csvdata['Category']) {
                    csvdata['Category'] = ''
                }
                csvdata['Auction Name'] = data.auctionlot_title
                csvdata['Amount'] = ''
                csvdata['bid_type'] = data.custom_auctionlot_bid_type
                csvdata['barcode'] = data.barcode
                csvdata['sku'] = data.sku
                const conditionType = allCondition.filter((k) => k.id == data.conditionTypeId)
                csvdata['Condition Type'] =
                    conditionType.length > 0 ? conditionType[0].description : ''
                csv.push(csvdata)
            }
        })

        if (csv.length) {
            setCsvData(csv)
            done(true)
            return true
        } else {
            setAlert('No item to bid', 'error')
            done(false)
            return false
        }
    }
    const [jsonCSV, setJsonCSV] = useState([])
    const [csvValues, setCSV] = useState({ bidCSV: [{ id: 0, title: 'Test Title', amount: 1000 }] })

    const csvValidation = Yup.object({
        bidCSV: Yup.array().of(
            Yup.object().shape({
                id: Yup.number()
                    .typeError('Enter valid id')
                    .min(1, 'Enter valid id')
                    .required('Id required!'),
                amount: Yup.number()
                    .typeError('Enter valid amount')
                    .min(1, 'Min value is 1')
                    .required('Amount required!'),
            }),
        ),
    })

    const formik = useFormik({
        initialValues: csvValues,
        validationSchema: validationArray,
        onSubmit: (values) => {},
        enableReinitialize: true,
    })
    const [uploadbulkform, setUploadbulkform] = useState([
        {
            label: 'ID',
            class: 'col-md-12',
            visible: false,
            name: 'header',
            formik: formik,
        },
    ])

    const initialValues = () => {
        let finaRes = {}
        let initVal = []
        for (var x = 0; x < jsonCSV.length; x++) {
            let csd = {}
            csd.id = jsonCSV[x].id
            csd.title = jsonCSV[x].title
            csd.amount = jsonCSV[x].amount
            csd.barcode = jsonCSV[x].barcode
            csd.category = jsonCSV[x].category
            initVal.push(csd)
            console.log(csd, jsonCSV[x], 'csd!!')
        }
        finaRes.bidCSV = initVal

        setCSV(finaRes)
    }

    useEffect(() => {
        initialValues()
    }, [jsonCSV])

    const handleOnDrop = (data) => {
        let jsoncsv = []
        for (var i = 1; i < data.length; i++) {
            console.log(data[i], 'data[i]')
            let ncsv = {
                id: data[i]['data'][0],
                title: data[i]['data'][1],
                category: data[i]['data'][2],
                barcode: data[i]['data'][6],
                amount: data[i]['data'][4],
            }
            console.log(ncsv, data[i]['data'][0], 'ncsv')
            jsoncsv.push(ncsv)
        }
        setJsonCSV(jsoncsv)
        // setBulkPoup(true)
        setTableView(true)
    }
    const handleOnRemoveFile = (data) => {
        setUploadbulkform([
            {
                label: 'ID',
                class: 'col-md-12',
                visible: false,
                name: 'ids',
                formik: formik,
            },
        ])
        setTableView(false)
    }

    const updateLoading = (data) => {
        setIsLoadingAuction(data)
    }

    const scrollToFunc = (ref) => window.scrollTo(0, ref.current.offsetTop)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }
    return (
        <>
            {/* <SEO
                title="View Auction"
                url={window.location.href}
                description="Terms of service are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service. Terms of service can also be merely a disclaimer, especially regarding the use of websitesTerms of service are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service. Terms of service can also be merely a disclaimer, especially regarding the use of websitesTerms of service are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service. Terms of service can also be merely a disclaimer, especially regarding the use of websitesTerms of service are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service. Terms of service can also be merely a disclaimer, especially regarding the use of websites"
            /> */}
            <div className="liveAuction customContainer">
                {isLoadingAuction ? (
                    <div className="liveAucLoader customContainer">
                        <Loaders isLoading={isLoadingAuction} name="product_view" loop={1} />
                    </div>
                ) : (
                    <>
                        <div className="row">
                            {/* <div className="col-12 mb-3">
                                <Breadcrumbs aria-label="breadcrumb" className="customBreadcrumbs">
                                    <Link to="/auction">{'Search'}</Link>
                                    <Typography color="textPrimary">
                                        {auctionDetails.title}
                                    </Typography>
                                </Breadcrumbs>
                            </div> */}
                        </div>
                        <div className="row auctionAssetsCnt">
                            <div className="col-lg-6 col-12 liveAucImages">
                                {Array.isArray(uri) ? (
                                    <>
                                        <div className="videoAuction">
                                            <div>
                                                <span className="material-icons">
                                                    personal_video
                                                </span>
                                                <h5>{t('video_will_be_streamed_soon')}</h5>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-lg-7 col-12">
                                        <div className="videoAuction">
                                            <ReactPlayer
                                                className="react-player"
                                                width="100%"
                                                height="100%"
                                                url={uri}
                                                playing
                                                stopOnUnmount={false}
                                                muted
                                                controls
                                                playsinline
                                                pip
                                            />
                                        </div>
                                    </div>
                                )}
                                {/* Mockup shows bidding here is this bidding for the products in the auction lot? */}
                                {/* <AvatarImage
                                    data={auctionDetails}
                                    className="auctionPageImg"
                                    folder="auction"
                                /> */}
                                {/* <ImageGallery
                                items={auctionImages}
                                thumbnailPosition="bottom"
                                showNav={false}
                                showBullets={false}
                                showFullscreenButton={true}
                                showPlayButton={false}
                            /> */}
                            </div>

                            <div className="col-lg-6 col-12 text-left actnDtlWrpr">
                                <div className="acContent">
                                    <div className="liveAucTitleCnt">
                                        <h2 className="actnTtrl mb-4">
                                            {' '}
                                            {localStorage.i18nextLng === 'for_arabic' &&
                                            auctionDetails.arabic_title
                                                ? auctionDetails.arabic_title
                                                : auctionDetails.title}
                                        </h2>
                                        <p>{auctionDetails.bid_type}</p>
                                    </div>
                                    <div className="pvTimerCnt">
                                        {auctionDetails.market_status === 'open' ? (
                                            <div className="pvTimerBox">
                                                <span className="material-icons-outlined">
                                                    timer
                                                </span>
                                                <h4>
                                                    <Timer
                                                        date_added={auctionDetails.date_added}
                                                        date_closed={auctionDetails.date_closed}
                                                        withText={1}
                                                        endText={'Time Left' + ':'}
                                                        startText={'Starts in' + ':'}
                                                    ></Timer>
                                                </h4>
                                            </div>
                                        ) : (
                                            <div className="pvTimerBox">
                                                <span className="material-icons-outlined">
                                                    timer
                                                </span>
                                                <label>{'Closed On'}</label>
                                                <h4>
                                                    {dateFormatFront(auctionDetails.date_closed)}
                                                </h4>
                                            </div>
                                        )}
                                    </div>

                                    {/* <h2 className="actnTtrl mb-4">{auctionDetails.title}</h2> */}
                                    <div className="acMiscInfo">
                                        <p>
                                            <span className="material-icons">today</span>
                                            {t('Bidding Starts')}:
                                        </p>
                                        <span>
                                            {dateTimeFormatFunction(auctionDetails.date_added)}
                                        </span>
                                    </div>
                                    <div className="acMiscInfo">
                                        <p>
                                            <span className="material-icons">event</span>
                                            {t('Bidding Ends')}:
                                        </p>
                                        <span>
                                            {dateTimeFormatFunction(auctionDetails.date_closed)}
                                        </span>
                                    </div>
                                    {/*<div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">place</span>
                                        Location:
                                    </p>
                                    {allLocations
                                        .filter((value) => auctionDetails.location_id === value.id)
                                        .map((location) => (
                                            <span key={location.id}>
                                                {location.name} - {location.address},{' '}
                                                {location.city} {location.state} {location.zipCode}
                                            </span>
                                        ))}
                                </div>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">gavel</span>Type:
                                    </p>
                                    <span className="capitalize">{auctionDetails.bid_type}</span>
                                </div>*/}
                                    <div className="acMiscInfo">
                                        <p>{t('Total Lots/Items')}:</p>
                                        {/*console.log(auctionDetails, 'auctionProducts')*/}
                                        <span>{search_allproducts.totalRecords}</span>
                                    </div>

                                    <AuctionView
                                        auctionDetails={auctionDetails}
                                        updateLoading={updateLoading}
                                    />

                                    <Accordion
                                        square
                                        expanded={expanded === `panel1`}
                                        key={1}
                                        onChange={handleChange(`panel1`)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1d-content"
                                            id={`panel1d-header`}
                                        >
                                            <h6 className="accTitle">{t('Description')}</h6>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <h6
                                                dangerouslySetInnerHTML={{
                                                    __html: auctionDetails.description,
                                                }}
                                            ></h6>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>

                            {/* <div className="col-12 col-lg-3">
                                <div className="acActBtn actnTmrDtl">
                                    <div className="acTimer">
                                        <h6>
                                            <span className="material-icons">timer</span>
                                        </h6>
                                        <p>
                                            {auctionDetails.date_closed ? (
                                                <>
                                                    <Timer
                                                        date_added={auctionDetails.date_added}
                                                        date_closed={auctionDetails.date_closed}
                                                        withText={1}
                                                        endText={'Time Left' + ':'}
                                                        startText={'Starts in' + ':'}
                                                    ></Timer>
                                                </>
                                            ) : null}
                                        </p>
                                    </div>
                                    <PrimaryButton
                                        id="liveAuct"
                                        onClick={() => scrollToFunc(lvActnRef)}
                                        label="Scroll to Bid"
                                        btnSize="small"
                                    />
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="row mx-0 my-4">
                        <div className="col-12 upldBlkWrpr">
                            <div className="lftCtnr text-left">
                                <h3>Interested in buying bulk?</h3>
                                <p>
                                    {' '}
                                    <a
                                        className="cursorDecoy"
                                        onClick={() => setBidInstruction(true)}
                                    >
                                        {' '}
                                        View bidding instructions{' '}
                                    </a>{' '}
                                </p>
                            </div>
                            <div className="rtContnr">
                                {manifestProducts.length ? (
                                    <CSVLink
                                        filename={'manifest.csv'}
                                        className="mnfstBtn plnBtn"
                                        asyncOnClick={true}
                                        onClick={(event, done) => {
                                            downloadManifest(event, done)
                                        }}
                                        data={csvData}
                                    >
                                        <img src="/assets/svg/download.svg" />
                                        Download complete manifest
                                    </CSVLink>
                                ) : null}

                                {isAuthenticated ? (
                                    <Button
                                        className="mnfstBtn upldBdnBtn"
                                        onClick={() => setBulkPoup(true)}
                                    >
                                        <img src="/assets/svg/upload.svg" />
                                        Upload bulk bidding file
                                    </Button>
                                ) : (
                                    <Button
                                        className="mnfstBtn upldBdnBtn"
                                        onClick={() => setLoginModal(true)}
                                    >
                                        Login to Bid
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div> */}
                    </>
                )}

                {isLoadingProject ? (
                    <div className="liveAucLoader grid customContainer">
                        <Loaders name="product_grid_view" isLoading={isLoadingProject} loop={4} />
                    </div>
                ) : (
                    <>
                        <div className="row" ref={lvActnRef}>
                            <div className="col-12">
                                <h2 className="laTitle my-4 mt-5">Auction Lots/Items</h2>
                            </div>
                            <div className="srcFltrWpr col-12 d-flex align-items-start justify-content-between">
                                <div className="form-inline my-2 my-lg-0 headerSearchForm position-relative">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="Search Products"
                                        aria-label="Search"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                    <Button
                                        id="liveAuction_search"
                                        onClick={handleSearchTextChange}
                                        className="btn"
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </div>
                                <div className="searchMiscFilters w-100 mb-5 d-flex justify-content-end align-items-end">
                                    {/* <div className="gridListToggle">
                                    <Button
                                        className={auctionView === 'Grid' ? 'active' : ''}
                                        onClick={() => setAuctionView('Grid')}
                                    >
                                        <span className="material-icons">apps</span>
                                    </Button>
                                    <Button
                                        className={auctionView === 'List' ? 'active' : ''}
                                        onClick={() => setAuctionView('List')}
                                    >
                                        <span className="material-icons">view_list</span>
                                    </Button>
                                </div> */}
                                    <Button
                                        id="liveAuction_filter_alt"
                                        className="fltrBtn"
                                        onClick={toggleDrawer('left', true, null)}
                                    >
                                        Filters{' '}
                                        <span className="material-icons ml-2">filter_alt</span>
                                    </Button>
                                    <div className="mr-2">
                                        <CustomSelect
                                            label={'Results per Page'}
                                            name="resultsPerPage"
                                            selectType="noBorder"
                                            value={formikSearch.values.limit}
                                            size="small"
                                            onChange={(event, editor) => {
                                                formikSearch.setFieldValue(
                                                    `limit`,
                                                    event.target.value,
                                                )
                                            }}
                                        >
                                            {pageOptions.map((opt, optindex) => (
                                                <option key={optindex} value={opt.value}>
                                                    {opt.show}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <CustomSelect
                                        label={'Sort by'}
                                        value={formikSearch.values.orderby}
                                        size="small"
                                        selectType="noBorder"
                                        name="orderby"
                                        // shrink={search.orderby !== '' ? true : false}
                                        onChange={(event, editor) => {
                                            formikSearch.setFieldValue(
                                                `orderby`,
                                                event.target.value,
                                            )
                                        }}
                                    >
                                        {sortShow.map((opt, optindex) => (
                                            <option value={opt.value}>{opt.show}</option>
                                        ))}
                                    </CustomSelect>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="searchResults col-md-12 col-12">
                                {auctionProducts &&
                                    auctionProducts.map((data, index) => (
                                        <GridView
                                            data={data}
                                            key={index}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    ))}
                            </div>
                            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                                <h6>
                                    {'Showing'} {search_allproducts.setDisp} {'of'} &nbsp;
                                    {search_allproducts.totalRecords}
                                </h6>
                                <Pagination
                                    count={Math.ceil(
                                        search_allproducts.totalRecords / formikSearch.values.limit,
                                    )}
                                    page={formikSearch.values.page}
                                    onChange={onHandlePage}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                />
                            </div>
                            {/* <div className="col-12"> */}
                            <Drawer
                                className="rightDrawer"
                                anchor={'right'}
                                open={state['right']}
                                onClose={toggleDrawer('right', false)}
                            >
                                <ProductView
                                    data={state.data}
                                    drawerHandler={toggleDrawer('right', false)}
                                    onClick={() => setState(false)}
                                />
                            </Drawer>
                            {/* <Drawer
                                className="fltrLeftDrawer"
                                anchor={'left'}
                                open={state['left']}
                                onClose={toggleDrawer('left', false)}
                            >
                                <DirectFilterPanel
                                    formik={formikSearch}
                                    clearSearch={clearSearchFilter}
                                    from={2}
                                />
                            </Drawer> */}
                            {/* </div> */}
                        </div>
                    </>
                )}

                <Popup
                    modaltitle="Upload Bulk Bidding File"
                    open={bulkPopup}
                    handleClose={() => {
                        setBulkPoup(false)
                        setCSV({ bidCSV: [] })
                    }}
                >
                    <div className="csvPopWrpr">
                        <div className="csvRdrWrpr">
                            <CSVReader
                                onDrop={handleOnDrop}
                                onError={handleOnError}
                                addRemoveButton
                                onRemoveFile={handleOnRemoveFile}
                            >
                                <span>Drop CSV file here or click to upload.</span>
                            </CSVReader>
                        </div>
                        {TableView ? (
                            <Formik
                                initialValues={csvValues}
                                enableReinitialize={true}
                                validationSchema={csvValidation}
                                onSubmit={async (values) => {
                                    await new Promise((r) => setTimeout(r, 500))
                                    values.bidCSV.map((csv) => {
                                        let bid = {
                                            id: csv.id,
                                            wsprice: csv.amount,
                                            bidding_type: auctionDetails.bid_type,
                                        }
                                        bidConfirm(bid)
                                    })
                                    setBulkPoup(false)
                                    setCSV({ bidCSV: [] })
                                }}
                            >
                                {({ values }) => (
                                    <Form>
                                        <FieldArray name="bidCSV">
                                            {({ insert, remove, push }) => (
                                                <div className="csvTable">
                                                    {values.bidCSV.length > 0 && (
                                                        <table className="table mt-4">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-left"
                                                                    >
                                                                        ID
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-left"
                                                                    >
                                                                        Title
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-left"
                                                                    >
                                                                        Category
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-left"
                                                                    >
                                                                        Barcode
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-left"
                                                                    >
                                                                        Amount
                                                                    </th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {values.bidCSV.map((csv, index) => (
                                                                    <>
                                                                        <tr>
                                                                            <td scope="row">
                                                                                <Field
                                                                                    name={`bidCSV.${index}.id`}
                                                                                    placeholder="id"
                                                                                    type="text"
                                                                                    value={csv.id}
                                                                                    disabled={true}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`bidCSV.${index}.id`}
                                                                                >
                                                                                    {(msg) => (
                                                                                        <div
                                                                                            style={{
                                                                                                color: 'red',
                                                                                            }}
                                                                                        >
                                                                                            {msg}
                                                                                        </div>
                                                                                    )}
                                                                                </ErrorMessage>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    name={`bidCSV.${index}.title`}
                                                                                    placeholder="Title"
                                                                                    type="text"
                                                                                    value={
                                                                                        csv.title
                                                                                    }
                                                                                    disabled={true}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    name={`bidCSV.${index}.category`}
                                                                                    placeholder="Category"
                                                                                    type="text"
                                                                                    value={
                                                                                        csv.category
                                                                                    }
                                                                                    disabled={true}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    name={`bidCSV.${index}.barcode`}
                                                                                    placeholder="barcode"
                                                                                    type="text"
                                                                                    value={
                                                                                        csv.barcode
                                                                                    }
                                                                                    disabled={true}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    name={`bidCSV.${index}.amount`}
                                                                                    placeholder="Enter Amount"
                                                                                    type="text"
                                                                                    value={
                                                                                        csv.amount
                                                                                    }
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`bidCSV.${index}.amount`}
                                                                                >
                                                                                    {(msg) => (
                                                                                        <div
                                                                                            style={{
                                                                                                color: 'red',
                                                                                            }}
                                                                                        >
                                                                                            {msg}
                                                                                        </div>
                                                                                    )}
                                                                                </ErrorMessage>
                                                                            </td>

                                                                            <td>
                                                                                <IconButton
                                                                                    type="button"
                                                                                    className="secondary text-danger"
                                                                                    onClick={() =>
                                                                                        remove(
                                                                                            index,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                                {/* <button>X</button> */}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            )}
                                        </FieldArray>
                                        {csvValues.bidCSV.length > 0 && (
                                            <PrimaryButton
                                                id="productView_liveAuct"
                                                type="submit"
                                                label="Submit Bid"
                                            />
                                        )}

                                        {/* <button type="submit" className="btn btn-primary">
                                    Submit Bid
                                </button> */}
                                    </Form>
                                )}
                            </Formik>
                        ) : null}
                    </div>
                </Popup>

                <Popup
                    modaltitle="Bidding Instructions"
                    open={bidInstruction}
                    handleClose={() => setBidInstruction(false)}
                >
                    <div className="csvPopWrpr">
                        <p>
                            To bulk bid on all the lots of an auction simply click the “Download
                            Complete Manifest” link. This will download a csv list with all the lots
                            of the auction. Open this with any spreadsheet editor and populate the
                            amount column with the amounts you would like to bid. Once completed
                            make sure you hit save then click the “Upload In Bulk” button. Here you
                            will drag and drop the file to the box or click it to upload. Next you
                            will see a review screen, once everything looks good press the “Submit
                            Bid” button. Now all your bids have been entered all at once! Happy
                            Bidding!
                        </p>
                    </div>
                </Popup>
            </div>
        </>
    )
}

export default VideoAuction
