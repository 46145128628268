import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Drawer, ListItem } from '@material-ui/core'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import './ProductCard.css'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import CommonContext from '../../../context/common/commonContext'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { dateTimeFormatFunction, useCustomMediaQuery } from '../../../common/components'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'
import Timer from '@/custom/common/timer'
const FileSaver = require('file-saver')
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const shareActions = [
    {
        icon: <object type="image/svg+xml" data="/assets/svg/fb.svg" />,
        name: 'Facebook',
    },
    {
        icon: <object type="image/svg+xml" data="/assets/svg/tw.svg" />,
        name: 'Twitter',
    },
    {
        icon: <object type="image/svg+xml" data="/assets/svg/pin.svg" />,
        name: 'Pinterest',
    },
    {
        icon: <object type="image/svg+xml" data="/assets/svg/mail.svg" />,
        name: 'Email',
    },
    {
        icon: <object type="image/svg+xml" data="/assets/svg/gplus.svg" />,
        name: 'Google',
    },
]

const SpecialEvent = (props) => {
    const commonContext = useContext(CommonContext)
    const { setSearchValue, allLocations } = commonContext
    const { t } = useTranslation()
    const history = useHistory()
    const [shareOpen, setShareOpen] = React.useState(false)
    const [calendarOpen, setCalendarOpen] = React.useState(false)
    const classes = useStyles()

    const [isFuture, setIsFuture] = useState(false)

    const handleFutureChange = (future) => {
        setIsFuture(future)
    }

    const [state, setState] = useState({
        bottom: false,
    })

    const formik = useFormik({
        initialValues: {
            auctionid: '',
        },
    })

    const auction = props.data

    const auctionRedirect = (auctID, auctTitle) => {
        if (props.videoAuction) {
            history.push(`videoAuction/${auctID}`)
        } else {
            history.push(`liveAuction/${auctID}`)
        }

        window.scrollTo(0, 0)
        // setSearchValue({
        //     filters: {
        //         auctionid: {
        //             value: [auctID],
        //             type: 'in',
        //             field: 'p.auctionid',
        //         },
        //     },
        // })
    }

    const handleRedirectInternal = (path) => {
        history.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    const handleClose = (state) => {
        state(false)
    }

    const handleOpen = () => {
        setCalendarOpen((prevState) => !prevState)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const calendarRedirect = (type) => {
        const locationDetails = allLocations
            .filter((inner) => inner.id === 1)
            .map(
                (filtered) =>
                    `${filtered.address}, ${filtered.city} ${filtered.state}  ${filtered.zipCode}`,
            )
        const event = {
            title: auction.title,
            description: auction.description,
            start: auction.date_added,
            end: auction.date_closed,
            location: locationDetails[0],
        }
        if (type === 'ical') {
            FileSaver.saveAs(ics(event), 'hello-world.ics')
        } else if (type === 'yahoo') {
            const RedirectURL = new URL(yahoo(event))
            window.open(RedirectURL, '_blank')
        } else if (type === 'outlook') {
            const RedirectURL = new URL(outlook(event))
            window.open(RedirectURL, '_blank')
        } else {
            const RedirectURL = new URL(google(event))
            window.open(RedirectURL, '_blank')
        }
    }

    const calendarActions = [
        {
            icon: <img src="./assets/svg/googleCal.svg" alt="Google" />,
            name: 'Google Calendar',
            type: 'google',
        },
        {
            icon: <img src="./assets/svg/yahooCal.svg" alt="Yahoo" />,
            name: 'Yahoo Calendar',
            type: 'yahoo',
        },
        {
            icon: <img src="./assets/svg/outlookCal.svg" alt="Yahoo" />,
            name: 'Outlook Calendar',
            type: 'outlook',
        },
        {
            icon: <img src="./assets/svg/outlookCal.svg" alt="iCal" />,
            name: 'iCal',
            type: 'ical',
        },
    ]

    return (
        <div className="specialEvent">
            <div className="specialEventLt">
                <div className="seImg cursorDecoy">
                    <img
                        src={`${global.site_url}/uploads/auction/${auction.avatar}`}
                        alt="Special Event"
                        onClick={() => auctionRedirect(auction.id, auction.title)}
                    />
                    <div className="seLinks">
                        {useCustomMediaQuery('(min-width: 600px)') ? (
                            <SpeedDial
                                button
                                ariaLabel="calendar example"
                                icon={
                                    <>
                                        <i
                                            style={{
                                                color: calendarOpen ? 'rgb(224, 179, 91)' : 'white',
                                            }}
                                            className="fas fa-calendar-plus"
                                        ></i>
                                        {/* {t('Add to Calender')} */}
                                    </>
                                }
                                // onClose={() => handleClose(setCalendarOpen)}
                                onClick={() => handleOpen()}
                                open={calendarOpen}
                                direction="up"
                            >
                                {calendarActions.map((action) => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        onClick={(e) => {
                                            calendarRedirect(action.type)
                                        }}
                                    />
                                ))}
                            </SpeedDial>
                        ) : (
                            <>
                                <Button className="atcBtn" onClick={toggleDrawer('bottom', true)}>
                                    <i className="fas fa-calendar-plus"></i>
                                    {/* {t('Add to Calender')} */}
                                </Button>
                                <Drawer
                                    anchor="bottom"
                                    open={state['bottom']}
                                    className="specialEventDrawer"
                                    onClose={toggleDrawer('bottom', false)}
                                    onOpen={toggleDrawer('bottom', true)}
                                >
                                    <div
                                        className={clsx(classes.list, 'bottom')}
                                        role="presentation"
                                        onKeyDown={toggleDrawer('bottom', false)}
                                    >
                                        <div className="calItems">
                                            <h3>{t('Add to Calender')}</h3>
                                            {calendarActions.map((action) => (
                                                <ListItem
                                                    button
                                                    onClick={(e) => {
                                                        calendarRedirect(action.type)
                                                    }}
                                                >
                                                    <span className="ciIcon">{action.icon}</span>
                                                    <span>{action.name}</span>
                                                </ListItem>
                                            ))}
                                        </div>
                                    </div>
                                </Drawer>
                            </>
                        )}
                    </div>
                </div>
                <div className="auctionTitleCndCnt">
                    <h2 className="seTitle">
                        {' '}
                        {localStorage.i18nextLng === 'for_arabic' && auction.arabic_title
                            ? auction.arabic_title
                            : auction.title}
                    </h2>
                    <div className="auctionCndContainer">
                        <p>{t('Live')}</p>
                    </div>
                </div>

                <div className="listTimer">
                    {/* <span className="material-icons-outlined">timer</span> */}
                    {auction.date_closed ? (
                        <>
                            <span>{isFuture ? `${t('starts_in')}:` : `${t('time_left')}:`}</span>

                            <p>
                                <Timer
                                    date_added={auction.date_added}
                                    date_closed={auction.date_closed}
                                    withText={1}
                                    // startText={t('starts_in')}
                                    // endText={t('ends_in')}
                                    onFutureChange={handleFutureChange}
                                />
                            </p>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="seActBtn mt-3">
                <PrimaryButton
                    onClick={() => auctionRedirect(auction.id, auction.title)}
                    label={`${t('View Auction')} (${auction?.all_projects_count} ${t('items')})`}
                    btnSize="small"
                />
            </div>
        </div>
    )
}
export default SpecialEvent
