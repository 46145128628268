import React, { useState, useContext, useEffect, useRef } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import CartItem from '@/custom/components/molecules/ProductCard/CartItem'
import './Checkout.css'
import CustomSelect from '@/product/components/atoms/Inputs/CustomSelect'
import { Button, InputAdornment, ListItem, Tooltip, Divider } from '@material-ui/core'
import RadioBox from '@/product/components/atoms/RadioBox'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import Popup from '@/custom/components/organisms/Popup'
import { useHistory } from 'react-router-dom'
import CartContext from '@/product/context/cart/cartContext'
import BuynowContext from '@/product/context/buynow/buynowContext'
import AuthContext from '@/product/context/auth/authContext'
import AlertContext from '@/product/context/alert/alertContext'
import PayContext from '../../product/context/payment/hyperpay/payment/hyperPayContext'
import CommonContext from '@/product/context/common/commonContext'
import AppointmentContext from '@/product/context/appointment/appointmentContext'
import ShipStationContext from '@/product/context/shipping/shipstation/shipstationContext'
import ShipEngineContext from '@/product/context/shipping/shipengine/shipengineContext'
import productcommonContext from '../../product/context/common/commonContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import StripeCardContext from '@/product/context/stripe/card/cardContext'
import StripeBankContext from '@/product/context/stripe/bank/bankContext'
import CreditContext from '@/product/context/credit/creditContext'
import OfflineContext from '@/product/context/offline/offlineContext'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import CurrencyContextFormat from '../../custom/common/currencyFormat'
import StripeCustomerContext from '@/product/context/stripe/customer/customerContext'
import CardDetails from '@/custom/components/organisms/CardDetails'
import BankDetails from '../../custom/components/organisms/BankDetails'
import HyperPay from './HyperPay'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { mapData, handleRedirectInternal, calculatePercentage } from '@/product/common/components'
import AddressComponent from '@/product/components/species/components/user/profile/AddressComponent'
import SkyflowCard from '@/product/components/organisms/SkyflowCardDetails'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {
    currencyFormat,
    dateFormatFrontDay,
    dateTimeFormatFunction,
    dateFormatFrontOnlyDate,
} from '@/custom/common/components'
import Loaders from '@/custom/components/molecules/Loaders'
import CustomAlert from '@/product/components/atoms/CustomAlert'
import UserContext from '../../product/context/user/userContext'
import OfferContext from '../../product/context/offer/offerContext'
import HyperPayContext from '../../product/context/payment/hyperpay/card/cardContext'
import HyperCard from '../Dashboard/HyperCard'
import { useTranslation } from 'react-i18next'

const today = new Date()
const tomorrow = new Date(today.getTime() + 1 * 24 * 3600000)
const twodays = new Date(today.getTime() + 2 * 24 * 3600000)
const threedays = new Date(today.getTime() + 3 * 24 * 3600000)
const oneweek = new Date(threedays.getTime() + 7 * 24 * 3600000)
const appointmentDate = [
    {
        value: dateFormatFrontOnlyDate(today),
        show: dateFormatFrontDay(today),
        label: 'Today',
    },
    {
        value: dateFormatFrontOnlyDate(tomorrow),
        show: dateFormatFrontDay(tomorrow),
        label: 'Tomorrow',
    },
    {
        value: dateFormatFrontOnlyDate(twodays),
        show: dateFormatFrontDay(twodays),
        label: 'In 2 days',
    },
]
//To calculate the processingfees
const getProcessingFees = (totalAmount, totalShipping, processingPercent) => {
    if (processingPercent && totalAmount) {
        let calculatedPercent = calculatePercentage(
            parseFloat(totalAmount) + parseFloat(totalShipping),
            processingPercent,
        )
        return calculatedPercent
    } else {
        return {
            percentAmount: 0,
        }
    }
}

const Checkout = (props) => {
    const cartContext = useContext(CartContext)
    const buynowContext = useContext(BuynowContext)
    const appointmentContext = useContext(AppointmentContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const offerContext = useContext(OfferContext)
    const hyperPayContext = useContext(HyperPayContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { t } = useTranslation()
    const { allLocations, configFeatures, allConfigurationVariables } = customCommonContext

    const {
        payCartWallet,
        responseStatus: responseStatusCredit,
        clearResponse: clearResponseCredit,
    } = creditContext
    const {
        shipping_quotes: shipstation_shipping_quotes,
        confirmShippingQuote: shipStationConfirmShippingQuote,
        shippingInitiate: shipStationShippingInitiate,
        responseStatus: responseStatusShipStation,
        clearResponse: clearShipstationResponse,
    } = useContext(ShipStationContext)
    const {
        shipping_quotes: shipEngine_shipping_quotes,
        confirmShippingQuote: shipEngineConfirmShippingQuote,
        shippingInitiate: shipEngineShippingInitiate,
        responseStatus: responseStatusShipEngine,
        clearResponse: clearShipengineResponse,
    } = useContext(ShipEngineContext)

    const { payCartOffline, responseStatus: responseStatusOffline } = offlineContext
    const [isLoading, setIsLoading] = useState(true)

    const [pendingPayment, setPendingPayment] = useState([])
    const [validationArray, setValidationArray] = useState('')
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const payContext = useContext(PayContext)
    const commonContext = useContext(CommonContext)
    const userContext = useContext(UserContext)
    const { responseStatus: responseStatusProduct, updateEntry } = useContext(productcommonContext)

    const history = useHistory()
    const { USStates, static_page, getStaticPage } = commonContext
    const { user, isAuthenticated, loadUser } = authContext

    const [checkoutType, setCheckoutType] = useState(props.match.params.type)
    const [reload, setReload] = useState(false)
    let [termsLink, setTermsLink] = useState([])
    const [paymentId, setPaymentId] = useState(0)
    const [dateCalendar, setDateCalendar] = useState(false)
    let [changeCard, setChangeCard] = useState(false)
    const [loaderState, setLoading] = useState(true)
    const [selectedCard, setSelectedCard] = useState(false)
    const [address, setAddress] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [managePay, setManagePay] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const [manageBank, setManageBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const [seletcedAddress, setSelectedAddress] = useState('')
    const [cards, setCards] = useState([])
    const [banks, setBanks] = useState([])
    let [shippingQuote, setShippingQuote] = useState(null)
    let [changeAddress, setChangeAddress] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)
    const [deliveryMode, setDeliveryMode] = useState([])
    const [checkoutId, setCheckoutId] = useState(0)
    const [submited, setIsSubmited] = useState(false)

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    let deliveryInternal = [
        { id: 1, description: 'Local Pickup' },
        // { id: 2, description: 'Shipping' },
    ]
    let getVariable = null
    if (props.match.params.type === 'buynow') {
        getVariable = buynowContext
    } else if (props.match.params.type === 'offer') {
        getVariable = offerContext
    } else {
        getVariable = cartContext
    }

    const toggleFullScreenPopup = (popup, status, data) => {
        setManage({ popup, status, data })
    }

    useEffect(() => {
        getStaticPage({ id: 'partial_payment_terms' })
        setCheckoutId(0)
        setPaymentId(0)
        toggleFullScreenCardPopup(false, 'new', null)
        toggleFullScreenPayPopup(false, 'new', null)
    }, [])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    const viewTerms = () =>
        window.open(
            termsLink
                ? `${global.site_url}/uploads/static_files/${termsLink}`
                : '/assets/docs/termsOfService.pdf',
        )

    const setUserAddress = () => {
        setAddress(user.address_details.filter((inner) => inner.status === 'active'))
        toggleFullScreenPopup(false, 'new', null)
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (user.address_details) {
                setUserAddress()
            }
        }
    }, [isAuthenticated, user && user.address_details])

    // useEffect(() => {
    //     if (checkout_pending_payment) {
    //         if (checkout_pending_payment.length) {
    //             setPendingPayment(checkout_pending_payment)
    //         }
    //         console.log('checkout_pending_payment', checkout_pending_payment)
    //     }
    // }, [checkout_pending_payment])

    useEffect(() => {
        setPendingPayment([])
        // if (props.match.params.type === 'buynow') {
        //     getPendingTransaction({
        //         cart_id: props.match.params.cart_id,
        //     })
        // }
    }, [props.match.params.type, props.match.params.cart_id])

    let {
        search_singlecart,
        changeOnCart,
        saveAddress,
        getSingleCartProducts,
        responseStatus,
        clearResponse,
        clearSingleCart,
    } = getVariable
    let { updateCheckout } = cartContext

    const {
        responseStatus: responseStatusHyper,
        getAllHyperPayCards,
        clearResponse: clearResponseHyper,
        get_all_card_details,
    } = hyperPayContext

    const { available_appointments, getAvailbleAppointments } = appointmentContext

    const {
        payCart,
        payResponse,
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    const { setAlert } = alertContext

    let [cartProducts, setCartProducts] = useState([])
    let [availAppoints, setAvailAppoints] = useState([])
    const [shippingSelected, setShippingSelected] = useState('')
    let [cartValues, setCartValues] = useState({
        processing_percent: 0,
        total_shipping: 0,
        total_amount: 0,
        total_processed_amount: 0,
        per_total: 0,
        total_tax: 0,
    })
    let [cartLocation, setCartLocation] = useState(null)

    let [allAvailableTime, setAllAvailableTime] = useState([])
    const [showShipping, setShipping] = useState(false)
    console.log(props.match.params.cart_id, 'idddd')

    const [search, setSearch] = useState({
        cart_id: props.match.params.cart_id,
        invoice_id: props.match.params.type === 'invoice' ? props.match.params.cart_id : 0,
        filters: {
            // active: {
            //     value: '2',
            //     type: 'notin',
            //     field: 'b.active',
            // },
        },
    })

    const [dataArray, setDataArray] = useState([])

    const addressUpdate = (id, noAlert = false) => {
        const payload = {
            cart_id: props.match.params.cart_id,
            invoice_address_id: id,
            updateType:
                checkoutType === 'buynow' ? 'buynow' : checkoutType === 'offer' ? 'offer' : '',
        }
        updateCheckout(payload, noAlert)
        // setRefresh(!refresh)
    }
    const cartValuesRef = useRef(cartValues)

    useEffect(() => {
        cartValuesRef.current = cartValues
    })

    useEffect(() => {
        if (responseStatusCredit) {
            if (responseStatusCredit.from === 'payCartWallet') {
                if (responseStatusCredit.status === 'success') {
                    handleRedirectInternal(history, 'invoices/all')
                    setAlert(responseStatusCredit.message, 'success')
                    clearResponseCredit()
                    loadUser()
                } else {
                    setAlert(responseStatusCredit.message, 'error')
                    clearResponseCredit()
                }
            }
        }
    }, [responseStatusCredit])

    useEffect(() => {
        if (responseStatusOffline) {
            if (responseStatusOffline.from === 'payCartOffline') {
                if (responseStatusOffline.status === 'success') {
                    handleRedirectInternal(history, 'invoices/all')
                }
            }
        }
    }, [responseStatusOffline])

    useEffect(() => {
        if (cartLocation) {
            const weeklyHours =
                cartLocation.weeklyHours && cartLocation.weeklyHours !== 'Closed'
                    ? cartLocation.weeklyHours.split('-')
                    : null

            const saturdayHours =
                cartLocation.saturdayHours && cartLocation.saturdayHours !== 'Closed'
                    ? cartLocation.saturdayHours.split('-')
                    : null

            const sundayHours =
                cartLocation.sundayHours && cartLocation.sundayHours !== 'Closed'
                    ? cartLocation.sundayHours.split('-')
                    : null

            const timeFormat = 'h:m a'
            let weeklyHoursStart = weeklyHours
                ? moment(weeklyHours[0], [timeFormat]).format('HH:mm')
                : 0
            let weeklyHoursEnd = weeklyHours
                ? moment(weeklyHours[1], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursStart = saturdayHours
                ? moment(saturdayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursEnd = saturdayHours
                ? moment(saturdayHours[1], [timeFormat]).format('HH:mm')
                : 0

            let sundayHoursStart = sundayHours
                ? moment(sundayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let sundayHoursEnd = sundayHours
                ? moment(sundayHours[1], [timeFormat]).format('HH:mm')
                : 0

            // 1 - Monday 2 - Tuesday
            setDataArray([
                {
                    date: 0,
                    minTime: sundayHoursStart,
                    maxTime: sundayHoursEnd,
                },
                {
                    date: 1,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 2,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 3,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 4,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 5,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 6,
                    minTime: saturdayHoursStart,
                    maxTime: saturdayHoursEnd,
                },
            ])
        } else {
            setDataArray([
                {
                    date: 0,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 1,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 2,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 3,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 4,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 5,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 6,
                    minTime: 8,
                    maxTime: 18,
                },
            ])
        }
    }, [cartLocation])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            let new_search = {
                ...search,
                shipping_calculation: formikPayment.values.delivery_mode == 1 ? false : true,
            }
            getSingleCartProducts(new_search)
            // getAvailbleAppointments()
        }
    }, [isAuthenticated])

    useEffect(() => {
        setIsLoading(false)
        setCartProducts(search_singlecart.cartrecords.length ? search_singlecart.cartrecords : [])
        setCartValues(search_singlecart.cartvalues)

        let location = []
        let locationID = search_singlecart.cartrecords.length
            ? search_singlecart.cartrecords[0].projects_location_id
            : 0

        if (locationID) {
            location = allLocations.filter((inner) => inner.id === locationID)
        }
        setCartLocation(location.length ? location[0] : null)
    }, [search_singlecart])

    // useEffect(() => {
    //     setAvailAppoints(
    //         available_appointments.records.length ? available_appointments.records : [],
    //     )
    // }, [available_appointments])

    useEffect(() => {
        if (responseStatus) {
            console.log('response ', responseStatus)

            if (
                responseStatus.from === 'cartchange' ||
                responseStatus.from === 'cartaddress' ||
                responseStatus.from === 'addToCart'
            ) {
                if (responseStatus.status === 'success') {
                    getSingleCartProducts(search)
                }
            }
        }
    }, [responseStatus])

    const [addAddress, setAddAddress] = useState(false)

    const toggleModal = () => {
        setAddAddress(!addAddress)
    }

    const validation = Yup.object({
        bill_first_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required'),
        bill_last_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required'),
        bill_address1: Yup.string().required('Required'),
        bill_city: Yup.string().required('Required'),
        bill_state: Yup.string().required('Required'),
        bill_zipcode: Yup.string()
            .min(4, 'Invalid zip code')
            .max(8, 'Invalid zip code')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            invoice_id: search.invoice_id,
            cart_id: search.cart_id,
            bill_first_name: cartValues.bill_first_name,
            bill_last_name: cartValues.bill_last_name,
            bill_address1: cartValues.bill_address1,
            bill_city: cartValues.bill_city,
            bill_state: cartValues.bill_state,
            bill_zipcode: cartValues.bill_zipcode,
        },
        validateOnBlur: false,
        validationSchema: validation,
        onSubmit: (values) => {
            saveAddress(values)
            toggleModal()
        },
    })

    const billingAddressInfo = [
        {
            label: 'First name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-12',
            name: 'bill_first_name',
            formik: formik,
        },
        {
            label: 'Last name',
            placeholder: 'Enter your last name',
            class: 'col-12',
            type: 'text',
            name: 'bill_last_name',
            formik: formik,
        },
        {
            label: 'Address',
            placeholder: 'Enter your address',
            class: 'col-12',
            type: 'text',
            name: 'bill_address1',
            formik: formik,
        },
        {
            label: 'City',
            placeholder: 'Enter your city',
            class: 'col-12',
            type: 'text',
            name: 'bill_city',
            formik: formik,
        },
        {
            label: 'State',
            placeholder: 'Enter your state',
            class: 'col-12',
            type: 'select',
            options: USStates,
            name: 'bill_state',
            formik: formik,
        },
        {
            label: 'Zip code',
            placeholder: 'Enter your Zip code',
            class: 'col-6',
            type: 'number',
            name: 'bill_zipcode',
            formik: formik,
        },
    ]

    const validationLocalPayment = {
        hyperpay_type: Yup.string().required(t('select_payment_type')),
        // appointmentID: Yup.string(),
        // no_of_cards: Yup.string().when(['payment_method'], {
        //     is: (a) => a === 'card',
        //     then: Yup.string().required('Required!'),
        // }),
        // pickupDay: Yup.string().when(['appointmentID'], {
        //     is: (a) => !a,
        //     then: Yup.string().required('Required!'),
        // }),
        // pickupTime: Yup.string().when(['appointmentID'], {
        //     is: (a) => !a,
        //     then: Yup.string().required('Required!'),
        // }),
        // bank_account_holder_name: Yup.string('Must be string').when(
        //     ['payment_method', 'no_of_cards'],
        //     {
        //         is: (a, b) => a === 'bank' && b === 'single',
        //         then: Yup.string().required('Required!'),
        //     },
        // ),
        // bank_account_number: Yup.number('Number required')
        //     .positive('Positive only allowed')
        //     .when(['payment_method', 'no_of_cards'], {
        //         is: (a, b) => a === 'bank' && b === 'single',
        //         then: Yup.number('Number required').required('Required!'),
        //     }),
        // bank_account_holder_type: Yup.string().when(['payment_method', 'no_of_cards'], {
        //     is: (a, b) => a === 'bank' && b === 'single',
        //     then: Yup.string().required('Required!'),
        // }),
        // bank_routing_number: Yup.number()
        //     .positive('Positive only allowed')
        //     .when(['payment_method', 'no_of_cards'], {
        //         is: (a, b) => a === 'bank' && b === 'single',
        //         then: Yup.number().required('Required!'),
        //     }),

        // amount: Yup.number()
        //     .min(1, 'Amount should be greater than or equal to one')
        //     .required('Required!'),
        payment_method: Yup.string().required('Required!'),
        partial: Yup.boolean(),
        amount: Yup.number().when('partial', {
            is: true,
            then: Yup.number()
                .max(
                    cartValues ? cartValues.total_due_amount : 0,
                    `Max. Amount ${
                        cartValues
                            ? currencyFormat(cartValues.total_due_amount, t)
                            : currencyFormat(0, t)
                    }`,
                )
                .min(
                    cartValues ? (cartValues.total_due_amount / 100) * 25 : 0,
                    `Min. Amount ${
                        cartValues
                            ? currencyFormat((cartValues.total_due_amount / 100) * 25, t)
                            : currencyFormat(0, t)
                    }`,
                )
                .required('Required!'),
        }),

        // termCheck: Yup.boolean().when('partial', {
        //     is: true,
        //     then: Yup.boolean().oneOf([true], 'Please accept terms and condition'),
        // }),
    }

    const validationShippingPayment = {
        payment_method: Yup.string().required('Required!'),
        hyperpay_type: Yup.string().required(t('select_payment_type')),
        // address: Yup.string().required('Address Required!'),
        // card_cvv: Yup.string()
        //     .min(3, 'Invalid CVV')
        //     .max(3, 'Invalid CVV')
        //     .when(['payment_method'], {
        //         is: (a, b) => a === 'card',
        //         then: Yup.string().required('Required!'),
        //     }),
        // amount: Yup.number()
        //     .min(1, 'Amount should be greater than or equal to one')
        //     .required('Required!'),
    }

    const formikPayment = useFormik({
        initialValues: {
            invoice_id: search.invoice_id,
            cart_id: search.cart_id,
            appointmentID: '',
            appointmentTime: '',
            address: '',
            pickupDay: '',
            pickupTime: '',
            payment_method: 'card',
            pay_type:
                checkoutType === 'buynow'
                    ? 'buynow'
                    : checkoutType === 'offer'
                    ? 'offer'
                    : 'auction',
            amount: '',
            bank_account_holder_name: '',
            bank_account_number: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            termCheck: 0,
            partial: false,
            card_cvv: '',
            hyperpay_type: 'card',
            delivery_mode: 1,
            // kount_session_id: window.kount_session_id.MercSessId,
        },
        validateOnBlur: false,
        validationSchema: Yup.object(validationArray),
        onSubmit: async (values) => {
            console.log('kkkkkkk', values)

            setLoading(true)
            let appointmentTime = ''
            if (values.pickupDay && values.pickupTime) {
                let pickupChanged = values.pickupDay + ' ' + values.pickupTime
                appointmentTime = moment(pickupChanged, 'MM-DD-YYYY HH:mm').format()
            }
            if (values.amount === '') {
                values.amount = cartValues.total_amount
            }
            // let formData = {
            //     invoice_id: values.invoice_id,
            //     cart_id: values.cart_id,
            //     payment_object: {
            //         id: values.appointmentID,
            //         appointmentTime: appointmentTime,
            //         invoice_id: values.invoice_id,
            //     },
            //     payment_method: values.payment_method,
            //     pay_type: values.pay_type,
            //     amount: values.partial ? values.amount : 0,
            // }
            if (user.phone == null || user.phone == '+' || user.phone == '+1' || user.phone == '') {
                setAlert('Please enter phone number', 'error')
                setLoading(false)
                toggleFullScreenUserPopup(true, 'new', null)
            } else if (cards.length == 0) {
                setAlert('Please enter card details', 'error')
                setLoading(false)
                history.push('/payments/saved_cards')
                // toggleFullScreenCardPopup(true, 'new', null)
            }
            // } else if (address.length == 0) {
            //     setAlert('Please enter address details', 'error')
            //     setLoading(false)
            //     toggleFullScreenPopup(true, 'new', null)
            // }
            // if (values.payment_method === 'wallet') {
            //     payCartWallet(formData)
            // } else if (values.payment_method === 'offline') {
            //     payCartOffline(formData)
            // } else {
            // if (selectedCard.id) {
            formikPayment.setSubmitting(true)

            let formData = {
                invoice_id: values.invoice_id,
                cart_id: search.cart_id,
                filters: {
                    // active: {
                    //     type: 'notin',
                    //     field: 'b.active',
                    //     value: 2,
                    // },
                },
                payment_object: {
                    id: values.appointmentID,
                    appointmentTime: appointmentTime ? appointmentTime : null,
                    invoice_id: values.invoice_id,
                },
                paymentInfo: {
                    // source: selectedCard.id,
                    skyflow_id: selectedCard.id,
                    customer: user.stripe_id,
                },
                payment_method: 'online',
                payment_type: values.payment_method !== 'bank' ? 'card' : values.payment_method,
                pay_type: values.pay_type,
                amount: values.amount,
                additional_buynow_data: {
                    invoice_address_id: formikPayment.values.address,
                },
                additional_buynow_update: true,
                project: 'bidashift',
            }
            console.log(cartValues, values, 'formData')
            payCart({
                invoice_id: values.invoice_id,
                cart_id: search.cart_id,
                payment_object: {
                    id: values.appointmentID,
                    appointmentTime: moment(values.pickupDay + ' ' + values.pickupTime),
                    invoice_id: cartValues.common_invoice,
                    additional_buynow_update: true,
                    additional_buynow_data: {
                        invoice_address_id: formikPayment.values.address,
                    },
                },
                hyperpay_type: values.hyperpay_type,
                payment_method: 'online',
                pay_type: values.pay_type,
                amount: values.amount,
                user_id: user.id,
                shipping_calculation: formikPayment.values.delivery_mode == 1 ? false : true,
                // email: user.email,
                //  card_cvv: values.card_cvv,
                // token: selectedCard.token,
            })
            // } else {
            //     setAlert('Please select the payment card / bank', 'error')
            // }
            // }
        },
    })

    const cvvForm = {
        formik: formikPayment,
        data: [
            {
                label: 'CVV',
                type: 'text',
                placeholder: 'Enter your CVV',
                class: 'col-12 col-sm-3 mt-2 mw-100',
                name: 'card_cvv',
                autoFocus: true,
            },
        ],
    }

    const requestShipping = (reInitiate, shippingMethod) => {
        //setIsLoading(true)
        // let shippingSelectedValue = shippingMethod ? shippingMethod : shippingSelected
        // const payload = {
        //     cart_id: props.match.params.cart_id,
        //     re_initiate: reInitiate,
        // }
        // if (shippingSelectedValue) {
        //     if (shippingSelectedValue === 'arta') {
        //         if (formikPayment.values.add_insurance) {
        //             payload.shipping_changes = {
        //                 custom_projects_arta_insurance: 'arta_transit_insurance',
        //             }
        //         } else {
        //             payload.shipping_changes = {
        //                 custom_projects_arta_insurance: 'no_arta_insurance',
        //             }
        //         }
        //         artaShippingInitiate(payload)
        //     } else if (shippingSelectedValue === 'shipEngine') {
        //         if (formikPayment.values.add_insurance) {
        //             payload.shipping_changes = {
        //                 custom_projects_engine_insurance: 'shipsurance',
        //             }
        //         } else {
        //             payload.shipping_changes = {
        //                 custom_projects_engine_insurance: 'none',
        //             }
        //         }
        //         shipEngineShippingInitiate(payload)
        //     } else if (shippingSelectedValue === 'shipStation') {
        //         // if (formikPayment.values.add_insurance) {
        //         //     payload.shipping_changes = {
        //         //         custom_projects_engine_insurance: 'shipsurance',
        //         //     }
        //         // } else {
        //         //     payload.shipping_changes = {
        //         //         custom_projects_engine_insurance: 'none',
        //         //     }
        //         // }
        //         shipStationShippingInitiate(payload)
        //     }
        // }
    }

    useEffect(() => {
        if (shippingSelected) {
            requestShipping(false)
        }
    }, [shippingSelected])

    // useEffect(() => {
    //     if (cartProducts.length && props.match.params.type === 'invoice') {
    //         let selectedAppointment = availAppoints.filter(
    //             (main) => main.id === cartProducts[0].appointment_id,
    //         )
    //         if (selectedAppointment.length) {
    //             formikPayment.values.appointmentID = selectedAppointment[0].id
    //             formikPayment.values.appointmentTime = selectedAppointment[0].appointmentTime
    //         }

    //         formikPayment.values.pay_type = cartProducts[0].buynow_autype
    //         setReload(!reload)
    //     }
    // }, [props.match.params, availAppoints, cartProducts])

    useEffect(() => {
        if (get_all_card_details) {
            console.log('skyflow_cards ', get_all_card_details)
            // if (formikPayment.values.cards === '') handleSelect(get_all_card_details[0])
            setCards(get_all_card_details.records)
            // formikPayment.setFieldValue('payment_ways', 'both')
        } else {
            setCards([])
        }
        return () => {
            setCards([])
        }
    }, [get_all_card_details])

    let paymentOptions = []

    let checkout_offline = false
    let checkout_online = false
    let checkout_wallet = false
    let checkout_partial = false

    if (checkoutType === 'buynow') {
        checkout_offline = configFeatures?.buynowcheckout_offline?.enabled
        checkout_online = configFeatures?.buynowcheckout_online?.enabled
        checkout_wallet = configFeatures?.buynowcheckout_wallet?.enabled
        checkout_partial = configFeatures?.buynowcheckout_partial?.enabled
    } else {
        checkout_offline = configFeatures?.auctioncheckout_offline?.enabled
        checkout_online = configFeatures?.auctioncheckout_online?.enabled
        checkout_wallet = configFeatures?.auctioncheckout_wallet?.enabled
        checkout_partial = configFeatures?.auctioncheckout_partial?.enabled
    }

    // if (checkout_offline) {
    //     paymentOptions.push({ value: 'offline', show: 'Offline' })
    // }

    if (checkout_online) {
        paymentOptions.push({ value: 'card', show: 'Card' })
    }

    const modeOfPayment = [
        {
            label: t('Select mode of payment'),
            placeholder: t('Select mode of payment'),
            type: 'select',
            class: 'col-12 mt-2',
            options: paymentOptions,
            name: 'payment_method',
            formik: formikPayment,
        },
        // {
        //     label: 'Make a partial payment',
        //     placeholder: 'Make a partial payment',
        //     type: 'switch',
        //     class: `${
        //         checkout_partial && formikPayment.values.payment_method !== 'offline'
        //             ? 'col-12 mb-4'
        //             : ' d-none'
        //     }`,
        //     name: 'partial',
        //     formik: formikPayment,
        // },
        // {
        //     label: 'Enter amount',
        //     name: 'amount',
        //     type: 'number',
        //     placeholder: 'Enter payment amount',
        //     class: `col-12 ${formikPayment.values.partial ? '' : 'd-none'}`,
        //     formik: formikPayment,
        // },
        // {
        //     label: [
        //         'I agree to the',
        //         <a target="_blank" onClick={viewTerms}>
        //             {'  '}
        //             {'Terms conditions'}{' '}
        //         </a>,
        //     ],
        //     name: 'termCheck',
        //     type: 'checkbox',
        //     class: `col-12 ${formikPayment.values.partial ? '' : 'd-none'}`,
        //     formik: formikPayment,
        // },
    ]
    //check for shipping or local pickup
    useEffect(() => {
        let deliveryInternal = []
        if (search_singlecart.cartrecords[0]) {
            deliveryInternal.push({ id: 1, description: t('Local Pickup') })
            console.log(search_singlecart.cartrecords[0], 'hellloooo')
            // if (search_singlecart.cartrecords[0].projects_shipping_check) {
            deliveryInternal.push({ id: 2, description: t('Shipping') })
            // }
            // if (search_singlecart.cartrecords[0].projects_local_pickup_check) {
            // deliveryInternal.push({ id: 1, description: t('Local Pickup') })
            // }
        }
        setDeliveryMode(deliveryInternal)
    }, [search_singlecart, localStorage.i18nextLng])
    //update delivery mode
    useEffect(() => {
        if (deliveryMode.length > 0) {
            if (
                formikPayment.values.delivery_mode == undefined ||
                formikPayment.values.delivery_mode == null
            ) {
                formikPayment.setFieldValue(
                    'delivery_mode',
                    deliveryMode.length ? deliveryMode[0].id : 1,
                )
            }
        }
    }, [deliveryMode])

    useEffect(() => {
        return async () => {
            // formikPayment.values.delivery_mode = undefined

            // setDeliveryMode([])

            await clearSingleCart()
        }
    }, [])

    let modeOfDelivery = [
        {
            title: t('Mode of delivery'),
            type: 'radio',
            name: 'delivery_mode',
            int: 1,
            item: deliveryMode,
            formik: formikPayment,
        },
    ]

    function intervals(startString, endString) {
        const intervalMinutes = 180
        var start = moment(startString, 'YYYY-MM-DD HH:mm')
        var end = moment(endString, 'YYYY-MM-DD HH:mm')
        start.minutes(Math.ceil(start.minutes() / intervalMinutes) * intervalMinutes)
        var result = []
        var current = moment(start)
        while (current < end) {
            result.push({
                value: moment(current).format('HH:mm'),
                show: `${current.format('h:mm a')}`,
            })
            current.add(intervalMinutes, 'minutes')
        }
        return result
    }

    useEffect(() => {
        const selectedDate = moment(formikPayment.values.pickupDay, 'MM-DD-YYYY').format()
        let weekDate = parseInt(moment(selectedDate).format('d'))
        let selectedWeekDay = dataArray.filter((x) => x.date === weekDate)
        if (weekDate !== null) {
            if (selectedWeekDay.length > 0) {
                if (selectedWeekDay[0].minTime && selectedWeekDay[0].maxTime) {
                    let dateSelected = moment(formikPayment.values.pickupDay, 'MM-DD-YYYY').format(
                        'YYYY-MM-DD',
                    )
                    let timeMinimum = moment(selectedWeekDay[0].minTime, 'HH:mm').format('HH:mm')
                    let timeMaximum = moment(selectedWeekDay[0].maxTime, 'HH:mm').format('HH:mm')
                    timeMinimum = moment(dateSelected + ' ' + timeMinimum).format()
                    timeMaximum = moment(dateSelected + ' ' + timeMaximum).format()
                    const changeData = intervals(timeMinimum, timeMaximum)
                    setAllAvailableTime(changeData)
                } else {
                    setAllAvailableTime([{ value: '', show: t('Closed no slots available') }])
                }
            } else {
                setAllAvailableTime([{ value: '', show: t('Closed no slots available') }])
            }
        } else {
            setAllAvailableTime([{ value: '', show: `Select a time` }])
        }
    }, [formikPayment.values.pickupDay])

    const confirmAppointment = (data) => {
        formikPayment.setFieldValue('appointmentID', data.id)
        formikPayment.setFieldValue('appointmentTime', data.appointmentTime)
    }
    const removeAppointment = () => {
        formikPayment.setFieldValue('appointmentID', '')
        formikPayment.setFieldValue('appointmentTime', '')
    }

    const handleLink = () => {
        if (props.match.params.type === 'buynow') {
            handleRedirectInternal(history, 'buynowcart')
        } else {
            handleRedirectInternal(history, 'cart')
        }
    }
    const handleSelect = (object) => {
        setSelectedCard(object)
        console.log('handleSelect ', object)
        search_singlecart.cartvalues.processing_fees = 0
        search_singlecart.cartvalues.processing_percent = 0
        if (object.object === 'bank_account') {
            formikPayment.setFieldValue('payment_method', 'bank')
            formikPayment.setFieldValue('cards', object.id)

            // if (object.country === 'US') {
            //     search_singlecart.cartvalues.processing_percent = parseFloat(
            //         configVariables.transaction_card,
            //     )
            // } else {
            //     search_singlecart.cartvalues.processing_percent = parseFloat(
            //         configVariables.transaction_international_card,
            //     )
            // }
            // let calculatedPercent = calculatePercentage(
            //     search_singlecart.cartvalues.total_amount,
            //     search_singlecart.cartvalues.processing_percent,
            // )
            // search_singlecart.cartvalues.processing_fees = calculatedPercent.percentAmount
            // search_singlecart.cartvalues.total_processed_amount = calculatedPercent.totalAmount
        } else {
            formikPayment.setFieldValue('payment_method', 'card')
            formikPayment.setFieldValue('cards', object.id)

            // if (object.country === 'US') {
            //     search_singlecart.cartvalues.processing_fees = parseFloat(
            //         configVariables.transaction_ach,
            //     )
            // } else {
            //     search_singlecart.cartvalues.processing_fees = parseFloat(
            //         configVariables.transaction_international_ach,
            //     )
            // }
            // search_singlecart.cartvalues.total_processed_amount =
            //     search_singlecart.cartvalues.total_amount +
            //     search_singlecart.cartvalues.processing_fees
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (user && user.id) {
                getAllHyperPayCards()
            }
        }
    }, [isAuthenticated, user])

    const confirmShippingQuote = () => {
        if (formikPayment.values.shipping_id) {
            if (cartProducts.length) {
                if (shippingSelected === 'shipStation') {
                    const shippingQuoteSelected =
                        shippingQuote &&
                        shippingQuote.filter(
                            (inner) => inner.serviceCode === formikPayment.values.shipping_id,
                            10,
                        )[0]
                    if (shippingQuoteSelected) {
                        let totalAmount = calculatePercentage(
                            shippingQuoteSelected?.shipmentCost + shippingQuoteSelected?.otherCost,
                            allConfigurationVariables?.mark_up_shipping || 0,
                        ).totalAmount
                        const payload = {
                            invoice_id: props.match.params.cart_id,
                            shipping_data: { ...shippingQuoteSelected, total: totalAmount },
                        }
                        shipStationConfirmShippingQuote(payload)
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (formikPayment.values.shipping_id) {
            if (cartProducts.length) {
                let totalAmount = 0
                if (shippingSelected === 'arta') {
                    const shippingQuoteSelected =
                        shippingQuote &&
                        shippingQuote.quotes &&
                        shippingQuote.quotes.filter(
                            (inner) => inner.id === parseInt(formikPayment.values.shipping_id, 10),
                        )[0]
                    if (shippingQuoteSelected) {
                        totalAmount = calculatePercentage(
                            shippingQuoteSelected.total,
                            allConfigurationVariables.mark_up_shipping || 0,
                        ).totalAmount
                    }
                } else if (shippingSelected === 'shipEngine') {
                    const shippingQuoteSelected =
                        shippingQuote &&
                        shippingQuote.rate_response &&
                        shippingQuote.rate_response.rates.filter(
                            (inner) => inner.rate_id === formikPayment.values.shipping_id,
                            10,
                        )[0]
                    if (shippingQuoteSelected) {
                        totalAmount = calculatePercentage(
                            shippingQuoteSelected?.shipping_amount.amount,
                            allConfigurationVariables?.mark_up_shipping || 0,
                        ).totalAmount
                    }
                } else if (shippingSelected === 'shipStation') {
                    const shippingQuoteSelected =
                        shippingQuote &&
                        shippingQuote.length &&
                        shippingQuote.filter(
                            (inner) => inner.serviceCode === formikPayment.values.shipping_id,
                        )[0]
                    if (shippingQuoteSelected) {
                        totalAmount = calculatePercentage(
                            shippingQuoteSelected?.shipmentCost + shippingQuoteSelected?.otherCost,
                            allConfigurationVariables?.mark_up_shipping || 0,
                        ).totalAmount
                    }
                }

                let calculatedPercent = getProcessingFees(
                    cartValuesRef.current.total_amount,
                    totalAmount,
                    cartValuesRef.current.processing_percent,
                )

                setCartValues({
                    ...cartValuesRef.current,
                    total_shipping: totalAmount,
                    processing_fees: calculatedPercent.percentAmount,
                })
            }
        }
    }, [formikPayment.values.shipping_id, formikPayment.values.address])

    useEffect(() => {
        if (
            (formikPayment.values.address &&
                search_singlecart &&
                search_singlecart.cartrecords &&
                search_singlecart.cartrecords.length &&
                parseInt(search_singlecart.cartrecords[0].invoice_address_id, 10) !==
                    parseInt(formikPayment.values.address, 10)) ||
            (formikPayment.values.address && initialLoad)
        ) {
            let addressSelected = null
            if (address) {
                address.map((data, index) => {
                    if (parseInt(data.id, 10) === parseInt(formikPayment.values.address, 10)) {
                        addressSelected = data
                    }
                })
            }

            if (addressSelected && cartProducts.length) {
                let shipping = 'shipStation'
                // if (cartProducts[0]?.custom_projects_ship_service === 'non_premium') {
                //     if (addressSelected.country === 'US') {
                //         shipping = 'shipStation'
                //     } else {
                //         shipping = 'arta'
                //     }
                // } else {
                //     shipping = 'arta'
                // }
                setShippingSelected(shipping)
                requestShipping(true, shipping)

                if (search_singlecart?.cartrecords[0]?.projects_shipping === shipping) {
                    requestShipping(false, shipping)
                    setInitialLoad(false)
                } else {
                    let payload = {
                        table: 'projects',
                        update_column: 'id',
                        update_id: cartProducts[0]?.projects_id,
                        shipping,
                    }

                    updateEntry(payload)
                    setInitialLoad(false)
                }
            }
        }
    }, [formikPayment.values.address, props.match.params.cart_id])

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const toggleFullScreenPayPopup = (popup, status, data) => {
        setManagePay({ popup, status, data })
    }

    const toggleFullScreenBankPopup = (popup, status, data) => {
        setManageBank({ popup, status, data })
    }

    useEffect(() => {
        if (address.length > 0) {
            formikPayment.values.address = address.filter((inner) => inner.is_primary === 1)[0]?.id
            addressUpdate(formikPayment.values.address, true)
        }
    }, [address])

    useEffect(() => {
        if (
            formikPayment.values.address &&
            search_singlecart &&
            search_singlecart.cartrecords &&
            search_singlecart.cartrecords.length
        ) {
            setShippingQuote('')
            addressUpdate(formikPayment.values.address)
        }
    }, [formikPayment.values.address])

    // useEffect(() => {
    //     if (
    //         get_all_card_details &&
    //         get_all_card_details.records.length
    //         // search_singlecart?.cartvalues?.total_amount <= configVariables?.stripe_ach_limit
    //     ) {
    //         if (formikPayment.values.cards === '') handleSelect(get_all_card_details.records[0])
    //         setCards(get_all_card_details.records)
    //         formikPayment.setFieldValue('payment_ways', 'both')
    //     }

    //     return () => {
    //         setCards([])
    //     }
    // }, [get_all_card_details])

    useEffect(() => {}, [formik.values.payment_method])
    useEffect(() => {
        if (responseStatusPayment) {
            console.log('responseStatusPayment', responseStatusPayment)

            if (responseStatusPayment.from === 'payment') {
                //setAlert(responseStatusPayment.message, responseStatusPayment.status)

                if (
                    responseStatusPayment.status === 'success' ||
                    responseStatusPayment.status === 'COMPLETED'
                ) {
                    console.log('Setting ID', responseStatusPayment.data.result.id)
                    // setPaymentId(responseStatusPayment.data.id)
                    setPaymentId(responseStatusPayment.data.result.id)
                    toggleFullScreenPayPopup(true, 'new', null)
                    // if (
                    //     responseStatusPayment?.data?.common_invoice &&
                    //     formikPayment.values.delivery_mode != 2
                    // ) {
                    //     console.log('kkkk')
                    //     handleRedirectInternal(
                    //         history,
                    //         `appointment/schedule/${responseStatusPayment.data.common_invoice}`,
                    //     )
                    // } else {
                    // handleRedirectInternal(history, 'invoices/all')
                    // }
                }
            }
        }
        return () => {
            clearResponsePayment()
        }
        //setLoading(false)
    }, [responseStatusPayment])

    useEffect(() => {
        if (formikPayment.values.delivery_mode) {
            let mode = formikPayment.values.delivery_mode
            // let new_cart = []
            // cartProducts.map((ele) => {
            //     let obj = {}
            //     obj.product_id = ele.project_id
            //     obj.qty = ele.qty
            //     new_cart.push(obj)
            // })
            // addToCart(
            //     {
            //         cart: new_cart,
            //         shipping_calculation: formikPayment.values.delivery_mode == 1 ? false : true,
            //     },
            //     'checkout_page',
            // )
            let new_search = {
                ...search,
                shipping_calculation: formikPayment.values.delivery_mode == 1 ? false : true,
            }
            getSingleCartProducts(new_search)
            if (mode == 1) {
                formikPayment.values.address = ''
                formikPayment.setFieldValue('address', '')
                cartValues.total_shipping = 0
                formikPayment.values.shipping_id = ''
                setCartValues({
                    ...cartValuesRef.current,
                    total_shipping: 0,
                })
            }
        }
        return () => {
            formikPayment.values.address = ''
            cartValues.total_shipping = 0
            formikPayment.values.shipping_id = ''
        }
    }, [formikPayment.values.delivery_mode])

    useEffect(() => {
        if (formikPayment.values.delivery_mode == 1) {
            setValidationArray(validationLocalPayment)
        } else {
            setValidationArray(validationShippingPayment)
        }
    }, [formikPayment.values.delivery_mode, cartValues.total_due_amount])

    useEffect(() => {
        if (shipstation_shipping_quotes) {
            if (
                shipstation_shipping_quotes &&
                shipstation_shipping_quotes.shipping_quotes &&
                shipstation_shipping_quotes.shipping_quotes.length
            ) {
                let apiResponse = shipstation_shipping_quotes.shipping_quotes[0].apiResponse
                if (apiResponse?.length > 0) {
                    let changedShippingQuote = apiResponse
                    let smallestAmount = apiResponse.sort(function (a, b) {
                        let shipCost = a.shipmentCost + a.otherCost
                        let reverseShipCost = b.shipmentCost + b.otherCost
                        return shipCost - reverseShipCost
                    })
                    changedShippingQuote = [smallestAmount[0]]
                    formikPayment.setFieldValue('shipping_id', smallestAmount[0].serviceCode)
                    setShippingQuote(changedShippingQuote)
                    setIsLoading(false)
                } else {
                    setShippingQuote(apiResponse)
                    setIsLoading(false)
                }
            }
        }

        return () => {
            setShippingQuote(null)
        }
    }, [shipstation_shipping_quotes])

    useEffect(() => {
        if (responseStatusShipStation) {
            if (responseStatusShipStation.from === 'confirmShippingQuote') {
                formikPayment.handleSubmit()
                clearShipstationResponse()
                // formikPayment.resetForm()
                clearResponse()
            }
        }
        return () => {
            clearShipstationResponse()
            // formikPayment.resetForm()
            clearResponse()
        }
    }, [responseStatusShipStation])

    const addressChnageHandler = (payload) => {
        setSelectedAddress(payload)
    }

    // const payType = {
    //     formik: formikPayment,
    //     data: [
    //         {
    //             label: t('payment_type'),
    //             type: 'radio',
    //             name: 'hyperpay_type',
    //             item: [
    //                 { id: 'card', description: t('card') },
    //                 { id: 'stc_pay', description: t('stc_pay') },
    //                 { id: 'apple_pay', description: t('apple_pay') },
    //             ],
    //             class: 'col-12',
    //         },
    //     ],
    // }

    const payType = {
        formik: formikPayment,
        data: [
            {
                label: t('payment_type'),
                type: 'radio',
                name: 'hyperpay_type',
                item: [
                    {
                        id: 'card',
                        description: (
                            <div className="payment-option">
                                <img
                                    src="/assets/svg/visa_pay_logo.svg"
                                    alt="STC Pay Logo"
                                    className="payment-logo"
                                />
                                {/* <p>t('card')</p> */}
                            </div>
                        ),
                    },
                    {
                        id: 'stc_pay',
                        description: (
                            <div className="payment-option">
                                <img
                                    src="/assets/svg/stc_pay_logo.svg"
                                    alt="STC Pay Logo"
                                    className="payment-logo"
                                />
                                {/* <p>{t('stc_pay')}</p> */}
                            </div>
                        ),
                    },
                    {
                        id: 'apple_pay',
                        description: (
                            <div className="payment-option">
                                <img
                                    src="/assets/svg/apple_pay_logo.svg"
                                    alt="Apple Pay Logo"
                                    className="payment-logo"
                                />
                                {/* <p> {t('apple_pay')} </p> */}
                            </div>
                        ),
                    },
                ],
                class: 'col-12',
            },
        ],
    }

    return (
        <>
            <div className="checkout customContainer">
                {isLoading ? (
                    <Loaders name="checkout" isLoading={isLoading} loop={1} />
                ) : (
                    <>
                        {/* {formikPayment.values.delivery_mode == 1 &&
                            availAppoints.length > 0 &&
                            !formikPayment.values.appointmentID && (
                                <>
                                    {availAppoints.map((data, index) => (
                                        <>
                                            <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                                                <span className="material-icons">{'Restore'}</span>
                                                <div>
                                                    <h2>
                                                        {t('you_have_an_appointment_schedule_for')}{' '}
                                                        {dateTimeFormatFunction(
                                                            data.appointmentTime,
                                                        )}
                                                        .
                                                    </h2>
                                                    <h4>
                                                        {t(
                                                            'you_can_add_items_to_the_same_appointment',
                                                        )}
                                                    </h4>
                                                </div>
                                                <PrimaryButton
                                                    id="confirmAppointment"
                                                    label={t('Continue')}
                                                    onClick={() => confirmAppointment(data)}
                                                    btnSize="small"
                                                />
                                            </div>
                                        </>
                                    ))}
                                </>
                            )} */}

                        <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs mb-2">
                            <Link onClick={() => handleLink()}>{t('cart')}</Link>
                            <Typography color="textPrimary">{t('checkout')}</Typography>
                        </Breadcrumbs>
                        <div className="mdOfDlvrWrpr">{mapData(modeOfDelivery)}</div>
                        <form onSubmit={formikPayment.handleSubmit} autoComplete="nofill">
                            <div className="checkoutCnt d-flex justify-content-between">
                                <div className="checkoutLt">
                                    <div className="review">
                                        <div className="d-flex align-items-center mb-3">
                                            <h1 className="checkoutHead m-0">
                                                {t('review_your_order')}
                                            </h1>
                                            <span>
                                                {cartProducts.length} {t('Items')}
                                            </span>
                                        </div>
                                        {cartProducts.length ? (
                                            <div className="cartProducts">
                                                <div>
                                                    {cartProducts.map((data, index) => (
                                                        <CartItem
                                                            key={index}
                                                            data={data}
                                                            from={
                                                                checkoutType === 'buynow'
                                                                    ? 'buynowcheckout'
                                                                    : checkoutType === 'buynow'
                                                                    ? 'offercheckout'
                                                                    : 'checkout'
                                                            }
                                                            changeOnCart={changeOnCart}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>

                                    {/* <div className="pickupAddress">
                                    <h1 className="checkoutHead m-0">{'Address for pickup'}</h1>
                                    <h6>
                                        {cartLocation && (
                                            <>
                                                {cartLocation.name} {cartLocation.address}{' '}
                                                {cartLocation.city} {cartLocation.state}{' '}
                                                {cartLocation.zipCode}
                                            </>
                                        )}
                                    </h6>
                                    <h5>
                                        {'Please bring along your emirates id for verification'}
                                    </h5>
                                </div> */}
                                    {formikPayment.values.delivery_mode == 2 ? (
                                        <div className="billingInfo">
                                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                                <h1 className="checkoutHead m-0 mt-2">
                                                    {t('Shipping Address')}
                                                </h1>
                                                <div className="errorMsg">
                                                    {formikPayment.touched.address &&
                                                        formikPayment.errors.address}
                                                </div>
                                                {address.length
                                                    ? !changeAddress && (
                                                          <Button
                                                              id="add_addresss"
                                                              className="checkoutEditBtn"
                                                              onClick={() =>
                                                                  setChangeAddress(!changeAddress)
                                                              }
                                                          >
                                                              {t('change')}
                                                          </Button>
                                                      )
                                                    : null}
                                            </div>
                                            <div className="savedAddressContainer">
                                                <Button
                                                    id="checkoutPage_new_address"
                                                    className="savedAddressCard addNew"
                                                    onClick={() =>
                                                        toggleFullScreenPopup(true, 'new', null)
                                                    }
                                                >
                                                    <span className="material-icons mr-1">add</span>{' '}
                                                    {t('Add new address')}
                                                </Button>

                                                {address &&
                                                    address.map((data, index) =>
                                                        changeAddress ? (
                                                            <div className="" key={'cart_' + index}>
                                                                <label
                                                                    className="w-100"
                                                                    htmlFor={`schedule_${index}`}
                                                                >
                                                                    <ListItem
                                                                        button
                                                                        onClick={() => {
                                                                            setChangeAddress(false)
                                                                            formikPayment.setFieldValue(
                                                                                'address',
                                                                                data.id,
                                                                            )
                                                                            setRefresh(!refresh)
                                                                        }}
                                                                        className={`p-0 ${
                                                                            parseInt(
                                                                                data.id,
                                                                                10,
                                                                            ) ===
                                                                            parseInt(
                                                                                formikPayment.values
                                                                                    .address,
                                                                                10,
                                                                            )
                                                                                ? 'checked'
                                                                                : 'unchecked'
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className="savedAddressCard"
                                                                            key={index}
                                                                        >
                                                                            <div>
                                                                                {data.is_primary ==
                                                                                    1 && (
                                                                                    <div className="dfltCard">
                                                                                        {t(
                                                                                            'Default',
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                                <h6 className="addrLabel">
                                                                                    {t('name')}
                                                                                </h6>
                                                                                <h4>
                                                                                    {data.first_name +
                                                                                        ' ' +
                                                                                        data.last_name}
                                                                                </h4>
                                                                                <h6 className="addrLabel mt-3">
                                                                                    {t('address')}
                                                                                </h6>
                                                                                <address>
                                                                                    {data.address1}
                                                                                    <br />
                                                                                    {data.address2}
                                                                                    <div>
                                                                                        <span>
                                                                                            {
                                                                                                data.city
                                                                                            }
                                                                                            ,
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                data.state
                                                                                            }
                                                                                            ,
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                data.zip
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </address>
                                                                            </div>
                                                                        </div>
                                                                    </ListItem>
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            parseInt(data.id, 10) ===
                                                                parseInt(
                                                                    formikPayment.values.address,
                                                                    10,
                                                                ) && (
                                                                <div className="savedAddressCard">
                                                                    <h6 className="addrLabel">
                                                                        {t('name')}
                                                                    </h6>
                                                                    {data.is_primary == 1 && (
                                                                        <div className="dfltCard">
                                                                            {t('Default')}
                                                                        </div>
                                                                    )}
                                                                    <h4>
                                                                        {data.first_name +
                                                                            ' ' +
                                                                            data.last_name}
                                                                    </h4>
                                                                    <h6 className="addrLabel mt-3">
                                                                        {t('address')}
                                                                    </h6>

                                                                    <address>
                                                                        {data.address1} <br />{' '}
                                                                        {data.address2}
                                                                        <div>
                                                                            <span>
                                                                                {data.city},
                                                                            </span>{' '}
                                                                            <span>
                                                                                {data.state},
                                                                            </span>
                                                                            <span>{data.zip}</span>
                                                                        </div>
                                                                    </address>
                                                                </div>
                                                            )
                                                        ),
                                                    )}
                                            </div>
                                            {/* {changeAddress && (
                                           <>
                                               <Button
                                                   onClick={() => toggleFullScreenPopup(true, 'new', null)}
                                                   className="checkoutAddBtn"
                                               >
                                                   <span className="material-icons">add</span>
                                                   Add Shipping Address
                                               </Button>
                                           </>
                                       )} */}

                                            <div className="co-address">
                                                <AddressComponent
                                                    data={manage}
                                                    onlyCountry={'SA'}
                                                    function={toggleFullScreenPopup}
                                                />
                                            </div>

                                            <>
                                                {shippingQuote &&
                                                    shippingQuote.length &&
                                                    shippingQuote.map((data, index) => (
                                                        <>
                                                            <ListItem button>
                                                                <div
                                                                    className="selectShipping"
                                                                    key={'cart_' + index}
                                                                    onClick={() => {
                                                                        formikPayment.setFieldValue(
                                                                            'shipping_id',
                                                                            data.serviceCode,
                                                                        )
                                                                    }}
                                                                >
                                                                    <div className="shipInfo">
                                                                        <h4>
                                                                            <CurrencyContextFormat
                                                                                amount={
                                                                                    calculatePercentage(
                                                                                        data.shipmentCost +
                                                                                            data.otherCost,
                                                                                        allConfigurationVariables?.mark_up_shipping ||
                                                                                            0,
                                                                                    ).totalAmount
                                                                                }
                                                                            />
                                                                            {' - ' +
                                                                                data.serviceName}
                                                                            {/* - {data.service_typee} */}
                                                                        </h4>

                                                                        {data.estimated_delivery_date ? (
                                                                            <p className="shipDelivery">
                                                                                {t('Delivery by')}{' '}
                                                                                {dateFormatFrontDay(
                                                                                    data.estimated_delivery_date,
                                                                                )}
                                                                            </p>
                                                                        ) : (
                                                                            <p className="shipDelivery">
                                                                                {t(
                                                                                    'Delivery date not available',
                                                                                )}
                                                                            </p>
                                                                        )}
                                                                        {/* Service:{' '}
                                                                        {capitalize(
                                                                            data.service_type,
                                                                        )} */}
                                                                    </div>
                                                                    {data.serviceCode ===
                                                                    formikPayment.values
                                                                        .shipping_id ? (
                                                                        <SecondaryButton
                                                                            id="selected"
                                                                            label={t('Selected')}
                                                                        />
                                                                    ) : (
                                                                        <PrimaryButton
                                                                            id="select"
                                                                            label={t('select')}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </ListItem>
                                                        </>
                                                    ))}
                                            </>
                                        </div>
                                    ) : null}
                                    {/* {formikPayment.values.delivery_mode == 1 ? (
                                        <>
                                            {formikPayment.values.appointmentID ? (
                                                <>
                                                    <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                                                        <span className="material-icons">
                                                            {'Restore'}
                                                        </span>
                                                        <div>
                                                            <h2>
                                                                You have an selected your previous
                                                                appointment scheduled at
                                                                {dateTimeFormatFunction(
                                                                    formikPayment.values
                                                                        .appointmentTime,
                                                                )}
                                                                .
                                                            </h2>
                                                            <h4>
                                                                if you want to remove the previous
                                                                appointment
                                                            </h4>
                                                        </div>
                                                        <PrimaryButton
                                                            id="removeAppointment"
                                                            label={'remove'}
                                                            onClick={() => removeAppointment()}
                                                            btnSize="small"
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="schedule">
                                                    <h1 className="checkoutHead">
                                                        {'Select a pickup date' + ':'}
                                                    </h1>
                                                    <div className="chCnt d-flex justify-content-start align-items-center">
                                                        {appointmentDate.map((data, index) => (
                                                            <div
                                                                className="selectDate d-flex justify-content-center align-items-center"
                                                                key={index}
                                                            >
                                                                <input
                                                                    name="pickupDay"
                                                                    value={data.value}
                                                                    onChange={
                                                                        formikPayment.handleChange
                                                                    }
                                                                    type="radio"
                                                                    id={`schedule_${index}`}
                                                                    hidden
                                                                ></input>
                                                                <label
                                                                    htmlFor={`schedule_${index}`}
                                                                >
                                                                    <ListItem button>
                                                                        <p>{data.show}</p>
                                                                        <span>{data.label}</span>
                                                                    </ListItem>
                                                                </label>
                                                            </div>
                                                        ))}
                                                        <div className="selectDate d-flex justify-content-center align-items-center">
                                                            <input
                                                                name="pickupDay"
                                                                checked={
                                                                    formikPayment?.values
                                                                        ?.pickupDay >
                                                                        dateFormatFrontOnlyDate(
                                                                            threedays,
                                                                        ) &&
                                                                    formikPayment?.values
                                                                        ?.pickupDay != ''
                                                                        ? true
                                                                        : false
                                                                }
                                                                type="radio"
                                                                id={`schedule_${appointmentDate.length}`}
                                                                hidden
                                                            ></input>
                                                            <label>
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        setDateCalendar(
                                                                            !dateCalendar,
                                                                        )
                                                                    }
                                                                >
                                                                    <p>
                                                                        {formikPayment?.values
                                                                            ?.pickupDay >
                                                                        dateFormatFrontOnlyDate(
                                                                            threedays,
                                                                        )
                                                                            ? formikPayment.values
                                                                                  .pickupDay
                                                                            : dateFormatFrontOnlyDate(
                                                                                  threedays,
                                                                              )}
                                                                    </p>
                                                                    <span>Custom date</span>
                                                                </ListItem>
                                                            </label>

                                                            {mapData([
                                                                {
                                                                    label: 'Custom date',
                                                                    placeholder: 'Appointment',
                                                                    disabled: false,
                                                                    disablePast: true,
                                                                    open: dateCalendar,
                                                                    onClose: () =>
                                                                        setDateCalendar(false),
                                                                    class:
                                                                        'col-md-4 col-sm-6 col-12 d-none',
                                                                    type: 'dateWithCustomPicker',
                                                                    name: 'pickupDay',
                                                                    minDate: dateFormatFrontOnlyDate(
                                                                        threedays,
                                                                    ),
                                                                    minDateMessage: `${'Date should be greater than the current date'} - ${dateTimeFormatFunction(
                                                                        threedays,
                                                                    )}`,
                                                                    maxDate: dateFormatFrontOnlyDate(
                                                                        oneweek,
                                                                    ),
                                                                    maxDateMessage: `${'Date should not be more than 7 days'} - ${dateTimeFormatFunction(
                                                                        oneweek,
                                                                    )}`,
                                                                    formik: formikPayment,
                                                                },
                                                            ])}
                                                        </div>
                                                    </div>

                                                    <p className="scheduleError">
                                                        {formikPayment.errors.pickupDay &&
                                                            formikPayment.touched.pickupDay &&
                                                            formikPayment.errors.pickupDay}
                                                    </p>

                                                    {formikPayment.values.pickupDay && (
                                                        <>
                                                            <h1 className="checkoutHead">
                                                                {'Select a pickup time' + ':'}
                                                            </h1>

                                                            <div className="chCnt timeSlots d-flex justify-content-start align-items-center flex-wrap">
                                                                {allAvailableTime
                                                                    .filter((availableTimeUnit) =>
                                                                        formikPayment.values
                                                                            .pickupDay <=
                                                                        moment().format(
                                                                            'MM-DD-YYYY',
                                                                        )
                                                                            ? moment(
                                                                                  availableTimeUnit.value,
                                                                                  'HH:mm',
                                                                              )
                                                                                  .format('HH:mm')
                                                                                  .valueOf() >=
                                                                              moment()
                                                                                  .format('HH:mm')
                                                                                  .valueOf()
                                                                            : availableTimeUnit.value,
                                                                    )
                                                                    .map((data, index) => (
                                                                        <div
                                                                            className="selectDate d-flex justify-content-center align-items-center"
                                                                            key={index}
                                                                        >
                                                                            <input
                                                                                name="pickupTime"
                                                                                value={data.value}
                                                                                onChange={
                                                                                    formikPayment.handleChange
                                                                                }
                                                                                type="radio"
                                                                                id={`scheduleTime_${index}`}
                                                                                hidden
                                                                            ></input>
                                                                            <label
                                                                                htmlFor={`scheduleTime_${index}`}
                                                                            >
                                                                                <ListItem button>
                                                                                    <p>
                                                                                        {data.show}
                                                                                    </p>
                                                                                </ListItem>
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                            <p className="scheduleError">
                                                                {formikPayment.errors.pickupTime &&
                                                                    formikPayment.touched
                                                                        .pickupTime &&
                                                                    formikPayment.errors.pickupTime}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    ) : null} */}
                                </div>

                                <div className="checkoutRt">
                                    <div className="orderSummary">
                                        <div>
                                            <h3>{t('order_summary')}</h3>
                                            <p>
                                                <label>{t('items')}:</label>
                                                <span>{cartProducts.length}</span>
                                            </p>
                                            <p>
                                                <label>{t('sub_total')}:</label>
                                                <span>
                                                    {currencyFormat(cartValues.per_total, t)}
                                                </span>
                                            </p>
                                            <p>
                                                <label>{t('vat')}: </label>
                                                <span>
                                                    {currencyFormat(cartValues.total_tax, t)}
                                                </span>
                                            </p>
                                            {cartValues.total_premium ? (
                                                <p>
                                                    <label>{t('platform_commission')}:</label>
                                                    <span>
                                                        {currencyFormat(
                                                            cartValues.total_premium,
                                                            t,
                                                        )}
                                                    </span>
                                                </p>
                                            ) : null}

                                            {parseInt(cartValues.total_invoice_shipping) !== 0 && (
                                                <p>
                                                    <label>{t('Shipping')}</label>
                                                    {currencyFormat(
                                                        cartValues.total_invoice_shipping,
                                                        t,
                                                    )}
                                                </p>
                                            )}

                                            <p>
                                                <label>{t('total_amount')}:</label>
                                                <span>
                                                    {currencyFormat(cartValues.total_amount, t)}
                                                </span>
                                            </p>

                                            {/* <Tooltip title="You can choose to make a partial payment of 25% and above of your total amount now and pay the remaining amount later."></Tooltip> */}
                                            {cartValues.total_deposit ? (
                                                <p>
                                                    <label>{t('bid_deposit')}:</label>
                                                    <span>
                                                        -{' '}
                                                        {currencyFormat(
                                                            cartValues.total_deposit,
                                                            t,
                                                        )}
                                                    </span>
                                                </p>
                                            ) : null}

                                            {cartValues.total_paid ? (
                                                <p>
                                                    <label>{t('total_paid')}:</label>
                                                    <span>
                                                        {currencyFormat(cartValues.total_paid, t)}
                                                    </span>
                                                </p>
                                            ) : null}
                                            {/* {cartValues.total_due_amount ? (
                                                <p>
                                                    <label>{t('pending_amount')}:</label>
                                                    <span>
                                                        {currencyFormat(
                                                            cartValues.total_due_amount,
                                                        )}
                                                    </span>
                                                </p>
                                            ) : null} */}
                                            {mapData(payType)}
                                            <div onClick={formikPayment.handleSubmit}>
                                                {pendingPayment.length ? (
                                                    <PrimaryButton
                                                        id="checkout_pay_make"
                                                        disabled={true}
                                                        label={t('make_payment')}
                                                    />
                                                ) : (
                                                    <PrimaryButton
                                                        id="checkout_pay_submit"
                                                        type="submit"
                                                        label={t('make_payment')}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <HyperPay
                            pay_type={formikPayment.values.hyperpay_type}
                            scriptSrc={paymentId}
                            data={managePay}
                            function={toggleFullScreenPayPopup}
                        />
                        {/* <div className="PaymentModal">
                <Popup
                    open={addAddress}
                    size="md"
                    handleClose={() => toggleModal()}
                    modaltitle="Change billing address"
                >
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {Object.values(mapData(billingAddressInfo))}
                        <PrimaryButton btnSize="large" type="submit" label={'Save'} />
                    </form>
                </Popup>
            </div> */}
                    </>
                )}
            </div>
        </>
    )
}

export default Checkout
